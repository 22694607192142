import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
  ModalOverlay,
  Select,
  ModalFooter,
  Button,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { Roles } from "constants/enums";
import { useEffect, useState } from "react";
import { changeRoleOfUser } from "services/UserService";

export default function ChangeUserRoleModal(changeUserProps: {
  user: {
    id: string;
    role: Roles;
  };
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  const toast = useToast();
  const [selectedRole, setSelectedRole] = useState<Roles>(
    changeUserProps.user?.role ?? Roles.user
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedRole(changeUserProps.user?.role ?? Roles.user);
  }, [changeUserProps.user?.role]);

  async function changeRole() {
    setIsLoading(true);
    const changeResp = await changeRoleOfUser(
      changeUserProps.user.id,
      selectedRole
    );
    if (changeResp) {
      changeUserProps.onClose();
      toast({
        title: "Role Changed",
        description: "The user role has been changed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description:
          changeResp?.message ?? "There was an error changing the user role",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  }
  return (
    <Modal isOpen={changeUserProps.isOpen} onClose={changeUserProps.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change User Role</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
            // Modal body contains a card and a select input for changing the user role default is the user role use chakra ui select input
            <Card mb={4} alignItems="center" p={4}>
              <Text fontSize="lg" fontWeight="bold" color="gray.500" mb={2}>
                Change User Role
              </Text>
              <Select
                placeholder="Select Role"
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value as Roles)}
              >
                {
                  // Map through the roles and display them as options
                  Object.keys(Roles).map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))
                }
              </Select>
            </Card>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isLoading={isLoading}
            disabled={selectedRole === changeUserProps.user?.role || isLoading}
            onClick={changeRole}
          >
            Save Changes
          </Button>

          <Button variant="ghost" onClick={changeUserProps.onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
