import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Menu,
  Select,
  useToast,
} from "@chakra-ui/react";
import { ISmtpConfig } from "constants/types";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { updateMailSmtp } from "services/MailService";

function EditSmtp(smtpProps: {
  smtpData: ISmtpConfig;
  closeEditMode: () => void;
}) {
  const initialValues = smtpProps.smtpData;
  const toast = useToast();

  const validationSchema = yup.object().shape({
    host: yup.string().required("Host is required"),
    port: yup.number().required("Port is required"),
    tls: yup.boolean().required("Tls is required"),
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
    sender: yup.string().required("Sender is required"),
  });

  const submitHandler = async (values: any, actions: any) => {
    const updatedSmtp = await updateMailSmtp(values as ISmtpConfig);
    if (updatedSmtp) {
      toast({
        title: "Success",
        description: "SMTP credentials updated successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error updating SMTP credentials",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    smtpProps.closeEditMode();
    actions.setSubmitting(false);
  };
  return (
    <>
      <Heading as={"h3"} size="lg" textAlign={"center"} mb={4}>
        Edit Credentials
      </Heading>
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Field name="host">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="host" mt={4}>
                    Host
                  </FormLabel>
                  <FormControl
                    isInvalid={form.errors.host && form.touched.host}
                  >
                    <Input {...field} id="host" placeholder="Host" />
                  </FormControl>
                </>
              )}
            </Field>
            <Field name="port">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="port" mt={4}>
                    Port
                  </FormLabel>

                  <FormControl
                    isInvalid={form.errors.port && form.touched.port}
                  >
                    <Input {...field} id="port" placeholder="Port" />
                  </FormControl>
                </>
              )}
            </Field>
            <Field name="tls">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="tls" mt={4}>
                    TLS
                  </FormLabel>
                  <FormControl
                    isInvalid={form.errors.tls && form.touched.secure}
                  >
                    <Menu
                      closeOnSelect={true}
                      placement="bottom-start"
                      matchWidth={true}
                    >
                      <Select
                        {...field}
                        id="tls"
                        placeholder="TLS"
                        size="md"
                        variant="outline"
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </Select>
                    </Menu>
                  </FormControl>
                </>
              )}
            </Field>
            <Field name="username">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="username" mt={4}>
                    Username
                  </FormLabel>
                  <FormControl
                    isInvalid={form.errors.username && form.touched.username}
                  >
                    <Input {...field} id="username" placeholder="Username" />
                  </FormControl>
                </>
              )}
            </Field>
            <Field name="password">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="password" mt={4}>
                    Password
                  </FormLabel>
                  <FormControl
                    isInvalid={form.errors.password && form.touched.password}
                  >
                    <Input {...field} id="password" placeholder="Password" />
                  </FormControl>
                </>
              )}
            </Field>
            <Field name="sender">
              {({ field, form }: { field: any; form: any }) => (
                <>
                  <FormLabel htmlFor="sender" mt={4}>
                    Sender
                  </FormLabel>
                  <FormControl
                    isInvalid={form.errors.sender && form.touched.sender}
                  >
                    <Input {...field} id="sender" placeholder="Sender" />
                  </FormControl>
                </>
              )}
            </Field>
            <Center mt={4}>
              <Button
                colorScheme="red"
                onClick={smtpProps.closeEditMode}
                mr={4}
              >
                Cancel
              </Button>
              <Button
                ml={4}
                colorScheme="green"
                isLoading={props.isSubmitting}
                type="submit"
                disabled={!(props.isValid && props.dirty)}
              >
                Update Credentials
              </Button>
            </Center>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditSmtp;
