import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { AuthProvider } from "services/AuthProvider";
import App from "App";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <AuthProvider>
        <HashRouter>
          <App />
        </HashRouter>
      </AuthProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
