/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
																																																																																	   
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Center,
  Icon,
  SimpleGrid,
  Skeleton,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { MdAddTask, MdFileCopy, MdSupervisedUserCircle } from "react-icons/md";

import PieCard from "views/admin/default/components/PieCard";
import { useEffect, useState } from "react";
import { IReports } from "constants/types";
import { getDashboardReports } from "services/DashboardService";
import { storageService } from "services";
import LastCreatedQuestions from "./components/LastCreatedQuestions";

export default function UserReports() {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState<IReports>();
  const [error, setError] = useState<string>(null);

  async function getReports() {
    setIsLoading(true);
    const reportsResponse = await getDashboardReports();
    if (reportsResponse) {
      setReports(reportsResponse);
    } else {
      setError("Error while getting reports");
    }

    setIsLoading(false);
  }

  useEffect(() => {
    getReports();
  }, [storageService.getToken()]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return isLoading || !reports ? (
    <Center mt={"30%"}>
      {" "}
      <Spinner size="xl" />
    </Center>
  ) : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Skeleton isLoaded={!isLoading || !reports}>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={MdSupervisedUserCircle}
                    color={brandColor}
                  />
                }
              />
            }
            name="Total Users"
            value={reports.usersCount}
          />
          {/* <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={
                      reports.mailServiceStatus
                        ? MdVerifiedUser
                        : MdErrorOutline
                    }
                    color={brandColor}
                  />
                }
              />
            }
            name="Mail Service Status"
            value={reports.mailServiceStatus ? "Active" : "Inactive"}
          /> */}
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdFileCopy} color={brandColor} />
                }
              />
            }
            name="Total Questions"
            value={reports.questionsCount}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={MdAddTask} color="white" />}
              />
            }
            name="Total Answers"
            value={reports.answersCount}
          />

          {/* <MiniStatistics growth="+23%" name="Sales" value="$574.34" /> */}
          {/* <MiniStatistics
            endContent={
              <Flex me="-16px" mt="10px">
                <FormLabel htmlFor="balance">
                  <Avatar src={Usa} />
                </FormLabel>
                <Select
                  id="balance"
                  variant="mini"
                  mt="5px"
                  me="0px"
                  defaultValue="usd"
                >
                  <option value="usd">USD</option>
                  <option value="eur">EUR</option>
                  <option value="gba">GBA</option>
                </Select>
              </Flex>
            }
            name="Your balance"
            value="$1,000"
          /> */}
        </SimpleGrid>
      </Skeleton>
      <Skeleton isLoaded={!isLoading || !reports}>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
          <LastCreatedQuestions questions={reports.lastCreatedQuestions} />
          <PieCard question={reports.questionWithStats} />
          {/* <DailyTraffic /> */}
        </SimpleGrid>
      </Skeleton>
      {/* <Skeleton isLoaded={!isLoading || !reports}>
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
          <TotalSpent />
          <WeeklyRevenue />
        </SimpleGrid>
      </Skeleton> */}
      <Skeleton isLoaded={!isLoading || !reports}>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          {/* <ComplexTable tableData={tableDataComplex} /> */}
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px">
            <MiniCalendar h="100%" minW="100%" selectRange={false} />
          </SimpleGrid>
        </SimpleGrid>
      </Skeleton>
    </Box>
  );
}
