import axios from "axios";
import Routes from "constants/constants";
import { CountdownUnits } from "constants/enums";
import { IQuestion } from "constants/types";

export async function addNewQuestion(
  questionText: string,
  options: { title: string }[],
  countdownTimeValue?: number,
  countdownTimeUnit?: CountdownUnits
) {
  try {
    const response = await axios.post(Routes.addQuestion, {
      questionText,
      options,
      countdownTimeValue,
      countdownTimeUnit,
    });
    return response.data;
  } catch (error: any) {
    return error;
  }
}

export async function updateQuestion(
  questionId: string,
  questionText: string,
  options: { title: string }[],
  countdownTimeValue?: number,
  countdownTimeUnit?: CountdownUnits
) {
  try {
    const response = await axios.post(
      `${Routes.updateQuestion}/${questionId}`,
      {
        questionText,
        options,
        countdownTimeValue,
        countdownTimeUnit,
      }
    );
    return response.data;
  } catch (error: any) {
    return error;
  }
}

export async function getAllQuestions(): Promise<IQuestion[]> {
  try {
    const response = await axios.get(Routes.getAllQuestions);
    const responseJson = await response.data;
    return responseJson.success ? (responseJson.data as IQuestion[]) : [];
  } catch (error) {
    return [];
  }
}

export async function deleteQuestion(questionId: string) {
  try {
    const response = await axios.delete(
      `${Routes.deleteQuestion}/${questionId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getSingleQuestion(
  questionId: string
): Promise<IQuestion | null> {
  try {
    const response = await axios.get(
      `${Routes.getSingleQuestion}/${questionId}`
    );
    return response.data.data as IQuestion;
  } catch (error) {
    return null;
  }
}

export async function changeActivityOfQuestion(
  questionId: string,
  isActive: boolean
): Promise<boolean> {
  try {
    const response = await axios.post(
      `${Routes.changeActivityOfQuestion}/${questionId}`,
      { isActive }
    );
    const responseJson = await response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}

export async function sendStatistics(questionId: string) {
  try {
    const response = await axios.get(`${Routes.sendStatistics}/${questionId}`);
    const responseJson = await response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}

export async function addCountdownTimeToQuestion(
  questionId: string,
  countdownTimeValue: number,
  countdownTimeUnit: CountdownUnits
) {
  try {
    const response = await axios.post(
      `${Routes.addCountdownTimeToQuestion}/${questionId}`,
      { countdownTimeValue, countdownTimeUnit }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getQuestionStatisticsById(questionId: string): Promise<{
  answersDict: { [key: number]: string[] };
  answers: any[];
  pieChartData: number[];
} | null> {
  try {
    const response = await axios.get(
      `${Routes.getQuestionStatistics}/${questionId}`
    );
    const responseJson = await response.data;
    if (responseJson.success) {
      return responseJson.data as {
        answersDict: { [key: number]: string[] };
        answers: any[];
        pieChartData: number[];
      } | null;
    }
  } catch (error) {
    return null;
  }
}

export async function deleteMultipleQuestions(
  questionIds: string[]
): Promise<boolean> {
  try {
    const response = await axios.post(`${Routes.deleteMultipleQuestions}`, {
      questionIds,
    });
    const responseJson = response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}

export async function getTotalNumberOfUsersWhoCanVote(): Promise<{
  totalNumberOfUsersWhoCanVote: number;
  totalNumberOfUsersWhoCanVoteWithProxies: number;
}> {
  try {
    const response = await axios.get(
      `${Routes.getTotalNumberOfUsersWhoCanVote}`
    );
    const responseJson = await response.data;
    return responseJson.data as {
      totalNumberOfUsersWhoCanVote: number;
      totalNumberOfUsersWhoCanVoteWithProxies: number;
    };
  } catch (error) {
    return {
      totalNumberOfUsersWhoCanVote: 0,
      totalNumberOfUsersWhoCanVoteWithProxies: 0,
    };
  }
}
