import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdQuestionAnswer,
  MdEmail,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Questions from "views/admin/questions";

import Users from "views/admin/users";
import Answers from "views/admin/answers";
import SmtpScreen from "views/admin/smtp";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    showInSidebar: true,
  },
  // {
  // 	name: 'NFT Marketplace',
  // 	layout: '/admin',
  // 	path: '/nft-marketplace',
  // 	icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  // 	component: NFTMarketplace,
  // 	secondary: true
  // },
  //   {
  //     name: "Data Tables",
  //     layout: "/admin",
  //     icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //     path: "/data-tables",
  //     component: DataTables,
  //     showInSidebar: true,
  //   },
  {
    name: "Questions",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/questions",
    component: Questions,
    showInSidebar: true,
  },
  {
    name: "Answers",
    layout: "/admin",
    icon: (
      <Icon as={MdQuestionAnswer} width="20px" height="20px" color="inherit" />
    ),
    path: "/answers",
    component: Answers,
    showInSidebar: true,
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
    showInSidebar: true,
  },
  {
    name: "SMTP",
    layout: "/admin",
    icon: <Icon as={MdEmail} width="20px" height="20px" color="inherit" />,
    path: "/smtp",
    component: SmtpScreen,
    showInSidebar: true,
  },
  //   {
  //     name: "Profile",
  //     layout: "/admin",
  //     path: "/profile",
  //     icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
  //     component: Profile,
  //     showInSidebar: true,
  //   },
  //   {
  //     name: "Question Stats",
  //     layout: "/admin",
  //     path: "/question-stats/:id",
  //     icon: (
  //       <Icon
  //         as={MdPieChartOutlined}
  //         width="20px"
  //         height="20px"
  //         color="inherit"
  //       />
  //     ),
  //     component: QuestionData,
  //     showInSidebar: false,
  //   },
  //   {
  //     name: "Sign In",
  //     layout: "/auth",
  //     path: "/sign-in",
  //     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
  //     component: SignInCentered,
  //   },
  // {
  // 	name: 'RTL Admin',
  // 	layout: '/rtl',
  // 	path: '/rtl-default',
  // 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  // 	component: RTL
  // }
];

export default routes;
