import React, {
  ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
} from "react";

import {
  Auth,
  UserCredential,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase/firebase";
import { setLocale } from "../services/StorageService";
import StorageKeys from "constants/storageKeys";
import { storageService } from "services";
import axios from "axios";

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);
export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  sendPasswordResetEmail?: (email: string) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  function signUp(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email);
  }

  async function refreshIdToken() {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const token = await currentUser.getIdToken();
      storageService.saveToken(token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }

  useEffect(() => {
    //function that firebase notifies you if a user is set
    const unsubsrcibe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      if (user) {
        setLocale(StorageKeys.user, user);
        refreshIdToken();
      }
    });

    const tokenRefreshTimer = setInterval(refreshIdToken, 50 * 60 * 1000); // Refresh token every 50 minutes
    return () => {
      unsubsrcibe();
      clearInterval(tokenRefreshTimer);
    };
  }, []);

  const values = {
    signUp,
    user,
    signIn,
    resetPassword,
    auth,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
