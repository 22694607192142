import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const FIREBASE_CONFIG = {
  apiKey: "AIzaSyARX8WXs2YgHEToNMC1_lfwicQ9b8SErdY",
  authDomain: "clipsas-poll.firebaseapp.com",
  databaseURL:
    "https://clipsas-poll-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "clipsas-poll",
  storageBucket: "clipsas-poll.appspot.com",
  messagingSenderId: "504311988151",
  appId: "1:504311988151:web:3093e71353bf8a04107232",
  measurementId: "G-PLW010EYTJ",
};

const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const auth = getAuth(firebaseApp);
