import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Progress,
  SimpleGrid,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  doc as firestoreDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import { IAdvancedUser, IQuestion } from "constants/types";
import ReactApexChart from "react-apexcharts";
import { AuthContext } from "services/AuthProvider";
import { storageService } from "services";
import { Roles } from "constants/enums";
import { RiFileExcel2Fill } from "react-icons/ri";
import { getTotalNumberOfUsersWhoCanVote } from "services/QuestionService";

function QuestionData() {
  const location = useLocation<{ questionData: IQuestion }>();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textFontSize = useBreakpointValue({
    base: "xl",
    md: "xxl",
    lg: "2xl",
    xl: "3xl",
  });
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const [question, setQuestion] = useState<IQuestion>(
    location.state.questionData || null
  );
  const [totalNumberOfUsersObj, setTotalNumberOfUsersObj] = useState<{
    totalNumberOfUsersWhoCanVoteWithProxies: number;
    totalNumberOfUsersWhoCanVote: number;
  }>({
    totalNumberOfUsersWhoCanVoteWithProxies: 0,
    totalNumberOfUsersWhoCanVote: 0,
  });
  const [answers, setAnswers] = useState<string[]>([]);
  const [pieChartData, setPieChartData] = useState<number[]>([]);
  const [obedienceBasedPieChartData, setObedienceBasedPieChartData] = useState<
    number[]
  >([]);
  const [totalVotesLoading, setTotalVotesLoading] = useState(true);
  const [obedienceBasedAnswers, setObedienceBasedAnswers] = useState<number[]>(
    []
  );
  const [userDataDict, setUserDataDict] = useState<{
    [key: string]: {
      id: string;
      obedienceVoteWeight: number;
      nameSurname: string;
      obedience: string;
      email: string;
    };
  }>({});
  const [answersDict, setAnswersDict] = useState<{
    [key: number]: string[];
  }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [obedienceBasedIsLoading, setObedienceBasedIsLoading] =
    useState<boolean>(true);

  let params = useParams<{
    id: string;
  }>();

  const authContext = useContext(AuthContext);

  function getPercentageOfValueByTotal(value: number, total: number): string {
    return ((value / total) * 100).toFixed(2) || "0";
  }

  function handleExportResultsAsExcel() {
    // Define your table data as an array of arrays
    const tableData = [
      ["ID", "Email", "Obedience", "Name Surname", "Answer"],
      ...Object.values(userDataDict).map((user) => [
        user.id,
        user.email,
        user.obedience ? user.obedience : "No Obedience",
        user.nameSurname ? user.nameSurname : "No Name",
        findAnswer(answersDict, user.id),
      ]),
    ];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    // Make column widths larger
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Export the workbook as an Excel file and download it
    XLSX.writeFile(workbook, "table-data.xlsx");
  }

  async function getTotalNumberOfUsersCanVote() {
    setTotalVotesLoading(true);
    // let totalNumberOfUsersWhoCanVoteWithProxies = 0;
    // let totalNumberOfUsersWhoCanVote = 0;
    // console.log("now get total number of users who can vote");
    const data = await getTotalNumberOfUsersWhoCanVote();
    // const db = getFirestore();
    // const q = query(collection(db, "users"), where("rightToVote", "==", true));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //   const userData = doc.data() as IAdvancedUser;
    //   totalNumberOfUsersWhoCanVote += 1;
    //   totalNumberOfUsersWhoCanVoteWithProxies += userData.voteWeight ?? 1;
    // });
    // setTotalNumberOfUsersObj({
    //   totalNumberOfUsersWhoCanVoteWithProxies,
    //   totalNumberOfUsersWhoCanVote,
    // });
    setTotalNumberOfUsersObj({
      totalNumberOfUsersWhoCanVoteWithProxies:
        data.totalNumberOfUsersWhoCanVoteWithProxies,
      totalNumberOfUsersWhoCanVote: data.totalNumberOfUsersWhoCanVote,
    });
    setTotalVotesLoading(false);
  }

  async function handleObedienceVoteWeightStatistics(answersDict: {
    [key: number]: string[];
  }) {
    setObedienceBasedIsLoading(true);
    const obedienceBasedAnswersDict: {
      [key: number]: number;
    } = {};
    const userDict: {
      [key: string]: number;
    } = {};
    let users: IAdvancedUser[] = [];
    const userIds = Object.values(answersDict).flat();
    // Get all users by userIds without onSnapshot
    const db = getFirestore();
    // const q = query(collection(db, "users"), where("id", "in", userIds));
    // for (const userId of userIds) {
    //   const docRef = firestoreDoc(db, "users", userId);
    //   const docSnap = await getDoc(docRef);
    //   if (docSnap.exists()) {
    //     users.push(docSnap.data() as IAdvancedUser);
    //   } else {
    //     console.log("No such document!");
    //   }
    // }

    // make the above for loop parallel
    const promises = userIds.map(async (userId) => {
      const docRef = firestoreDoc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as IAdvancedUser;
      } else {
        console.log("No such document!");
      }
    });
    users = await Promise.all(promises);

    // console.log(q);
    // const querySnapshot = await getDocs(q);
    users.forEach((user) => {
      userDict[user.id] = user.obedienceVoteWeight;
      setUserDataDict((prev) => ({
        ...prev,
        [user.id]: {
          id: user.id,
          obedienceVoteWeight: user.obedienceVoteWeight,
          email: user.email,
          nameSurname: user.name + " " + user.surname,
          obedience: user.obedience,
        },
      }));
    });
    // Now set the obedienceBasedAnswersDict by multiplying the answersDict with userDict values
    question.options.forEach((option, index) => {
      obedienceBasedAnswersDict[index] = 0;
    });
    Object.keys(answersDict).forEach((answerDictKey: string) => {
      const key = parseInt(answerDictKey);
      let calculatedUserIds: string[] = [];
      // the reduce function takes two parameters: a function, and an initial value
      obedienceBasedAnswersDict[key] = answersDict[key].reduce(
        // the function takes two parameters: the accumulator and the current value
        (acc, curr) => {
          if (!calculatedUserIds.includes(curr)) {
            calculatedUserIds.push(curr);
            return acc + userDict[curr];
          } else {
            // dont add the obedienceVoteWeight if the user has already been calculated
            return acc;
          }
        },
        // the initial value is 0
        0
      );
    });
    setObedienceBasedPieChartData(
      Object.values(obedienceBasedAnswersDict).map((answer) => answer)
    );
    setObedienceBasedAnswers(
      Object.values(obedienceBasedAnswersDict).map((answer) => answer)
    );
    setObedienceBasedIsLoading(false);
  }

  function findAnswer(
    answersDict: { [key: string]: string[] },
    userId: string
  ): string {
    for (const key in answersDict) {
      if (
        answersDict.hasOwnProperty(key) &&
        answersDict[key].includes(userId)
      ) {
        return question.options[parseInt(key)]?.title ?? "No answer";
      }
    }

    return "No answer";
  }

  const pieChartOptions: any = {
    labels: question.options.map((option) => option.title),
    // 6 different colors
    colors: [
      "#4318FF",
      "#6AD2FF",
      "#EFF4FB",
      "#FF0000",
      "#ffff00",
      "#00ff00",
    ].slice(0, question.options.length),
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [
        "#4318FF",
        "#6AD2FF",
        "#EFF4FB",
        "#FF0000",
        "#ffff00",
        "#00ff00",
      ].slice(0, question.options.length),
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  useEffect(() => {
    const db = getFirestore();
    const q = query(
      collection(db, "answers"),
      where("questionId", "==", params.id)
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      setIsLoading(true);
      setObedienceBasedIsLoading(true);
      getTotalNumberOfUsersCanVote();
      const answersDict: {
        [key: number]: string[];
      } = {};
      question.options.forEach((option, index) => {
        answersDict[index] = [];
      });
      const answers: any = [];
      // for (const doc of querySnapshot.docs) {
      //   const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
      //   // Get Answered user data from users collection based on answeredBy
      //   const userAnswered = (await getDoc(docRef)).data() as IAdvancedUser;
      //   console.log("userAnswered", userAnswered);
      //   answersDict[doc.data().answerIndex] = [
      //     ...(answersDict[doc.data().answerIndex] || []),
      //     // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
      //     ...Array(userAnswered.voteWeight || 1).fill(doc.data().answeredBy),
      //   ];
      //   console.log(`${doc.id} => ${JSON.stringify(doc.data())}`);
      //   answers.push(doc.data().answeredBy);
      // }

      // make the above for loop parallel
      await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
          // Get Answered user data from users collection based on answeredBy
          const userAnswered = (await getDoc(docRef)).data() as IAdvancedUser;
          answersDict[doc.data().answerIndex] = [
            ...(answersDict[doc.data().answerIndex] || []),
            // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
            ...Array(userAnswered.voteWeight || 1).fill(doc.data().answeredBy),
          ];
          answers.push(doc.data().answeredBy);
        })
      );

      setAnswers(answers);
      setPieChartData(
        Object.values(answersDict).map((answer) => answer.length)
      );
      setAnswersDict(answersDict);
      setIsLoading(false);
      handleObedienceVoteWeightStatistics(answersDict);
    });
    // //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, [question]);

  return (
    <SimpleGrid
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      columns={{ base: 1, md: 1 }}
      gap="20px"
      mb="20px"
    >
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="start"
        w="100%"
        mb="8px"
      >
        {
          <Text
            color={textColor}
            fontSize={{
              base: "lg",
              md: "xl",
              lg: "2xl",
              xl: "3xl",
            }}
            fontWeight="600"
            mt="4px"
          >
            Question: {question.questionText}
          </Text>
        }
        {!isLoading &&
          (totalVotesLoading ? (
            <Spinner />
          ) : (
            <Stack>
              <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
                {/* Number of vote casts : {answers.length} */}
                {`Users : ${answers.length}/${
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote
                } - % ${getPercentageOfValueByTotal(
                  answers.length,
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote
                )}`}
              </Text>
              <Progress
                colorScheme="brand"
                backgroundColor={"gray.200"}
                size="sm"
                value={answers.length}
                max={totalNumberOfUsersObj.totalNumberOfUsersWhoCanVote}
                borderRadius="10px"
                w="100%"
              />
              <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
                {/* Number of vote casts : {answers.length} */}
                {`Users (Including Proxies) : ${pieChartData.reduce(
                  (a, b) => a + b,
                  0
                )}/${
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                } - % ${getPercentageOfValueByTotal(
                  pieChartData.reduce((a, b) => a + b, 0),
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                )}`}
              </Text>
              <Progress
                colorScheme="brand"
                backgroundColor={"gray.200"}
                size="sm"
                value={pieChartData.reduce((a, b) => a + b, 0)}
                max={
                  totalNumberOfUsersObj.totalNumberOfUsersWhoCanVoteWithProxies
                }
                borderRadius="10px"
                w="100%"
              />
              {/* <Text color={textColor} fontSize="xl" fontWeight="600" mt="4px">
              Number Of Users Who Can Vote: {totalNumberOfUsersWhoCanVote}
            </Text> */}
            </Stack>
          ))}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px">
        {isLoading ? (
          <Flex
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
            <Text fontSize="xl" color="black" fontWeight="700" mb="5px">
              Loading...
            </Text>
          </Flex>
        ) : (
          <Card p="20px" alignItems="center" flexDirection="column" w="100%">
            <Text
              fontSize={{
                base: "md",
                md: "lg",
                lg: "xl",
                xl: "2xl",
              }}
              color="black"
              fontWeight="700"
              mb="5px"
            >
              In line with the equally weighted votes
            </Text>
            {answers.length === 0 ? (
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg="brand.500"
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xl"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  No Votes Yet
                </Text>
              </Flex>
            ) : (
              <ReactApexChart
                options={pieChartOptions}
                series={pieChartData}
                type="pie"
                width="100%"
                // height="55%"
              />
            )}
            <Card
              bg={cardColor}
              flexDirection="row"
              justifyContent={"center"}
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              {pieChartData.length > 0 && answers.length > 0 && (
                <SimpleGrid
                  columns={{ base: 2, md: 2, lg: 2, xl: 2 }}
                  gap="10px"
                >
                  {question.options.map((option, index) => (
                    <Flex
                      direction="column"
                      py="5px"
                      alignItems={"center"}
                      key={
                        // eslint-disable-next-line react/no-array-index-key
                        option.title
                      }
                    >
                      <Flex align="center">
                        <Box
                          h="8px"
                          w="8px"
                          bg={pieChartOptions.colors[index]}
                          borderRadius="50%"
                          me="4px"
                        />
                        <Text
                          fontSize={textFontSize}
                          color="secondaryGray.600"
                          fontWeight="700"
                          mb="5px"
                        >
                          {option.title}
                        </Text>
                      </Flex>
                      <Text
                        fontSize={textFontSize}
                        color={textColor}
                        fontWeight="700"
                      >
                        {
                          // find the percentage of the option
                          "%" +
                            (
                              (pieChartData[index] /
                                // PieChart data values sum
                                (pieChartData.reduce((a, b) => a + b, 0) ||
                                  1)) *
                              100
                            ).toFixed(2) || 0
                        }
                      </Text>
                      {
                        // VOTE count
                        <Text
                          fontSize={{
                            base: "xs",
                            md: "md",
                            lg: "lg",
                            xl: "xl",
                          }}
                          color="secondaryGray.600"
                          mb="5px"
                        >
                          {pieChartData[index]} Votes
                        </Text>
                      }
                      {index !== question.options.length - 1 && (
                        <VSeparator
                          mx={{ base: "60px", xl: "60px", "2xl": "60px" }}
                        />
                      )}
                    </Flex>
                  ))}
                </SimpleGrid>
              )}
            </Card>
          </Card>
        )}

        {obedienceBasedIsLoading ? (
          <Flex
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
            <Text fontSize="xl" color="black" fontWeight="700" mb="5px">
              Loading...
            </Text>
          </Flex>
        ) : (
          <Card p="20px" alignItems="center" flexDirection="column" w="100%">
            <Text
              fontSize={{
                base: "md",
                md: "lg",
                lg: "xl",
                xl: "2xl",
              }}
              color="black"
              fontWeight="700"
              mb="5px"
            >
              In line with the voting rights of the Obedience
            </Text>
            {answers.length === 0 ? (
              <Flex align="center">
                <Box
                  h="8px"
                  w="8px"
                  bg="brand.500"
                  borderRadius="50%"
                  me="4px"
                />
                <Text
                  fontSize="xl"
                  color="secondaryGray.600"
                  fontWeight="700"
                  mb="5px"
                >
                  No Votes Yet
                </Text>
              </Flex>
            ) : (
              <ReactApexChart
                options={pieChartOptions}
                series={obedienceBasedPieChartData}
                type="pie"
                width="100%"
                // height="55%"
              />
            )}

            <Card
              bg={cardColor}
              flexDirection="row"
              justifyContent={"center"}
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              {pieChartData.length > 0 && answers.length > 0 && (
                <SimpleGrid
                  columns={{ base: 2, md: 2, lg: 2, xl: 2 }}
                  gap="10px"
                >
                  {question.options.map((option, index) => (
                    <>
                      <Flex
                        direction="column"
                        py="5px"
                        alignItems={"center"}
                        key={
                          // eslint-disable-next-line react/no-array-index-key
                          option.title
                        }
                      >
                        <Flex align="center">
                          <Box
                            h="8px"
                            w="8px"
                            bg={pieChartOptions.colors[index]}
                            borderRadius="50%"
                            me="4px"
                          />
                          <Text
                            fontSize={textFontSize}
                            color="secondaryGray.600"
                            fontWeight="700"
                            mb="5px"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text
                          fontSize={textFontSize}
                          color={textColor}
                          fontWeight="700"
                        >
                          {
                            // find the percentage of the option
                            "%" +
                              (
                                (obedienceBasedPieChartData[index] /
                                  obedienceBasedAnswers.reduce(
                                    (a, b) => a + b,
                                    0
                                  )) *
                                100
                              ).toFixed(2) || 0
                          }
                        </Text>
                        {
                          // VOTE count
                          <Text
                            fontSize={{
                              base: "xs",
                              md: "md",
                              lg: "lg",
                              xl: "xl",
                            }}
                            color="secondaryGray.600"
                            mb="5px"
                          >
                            {obedienceBasedPieChartData[index]} Votes
                          </Text>
                        }
                        {index !== question.options.length - 1 && (
                          <VSeparator
                            mx={{ base: "60px", xl: "60px", "2xl": "60px" }}
                          />
                        )}
                      </Flex>
                    </>
                  ))}
                </SimpleGrid>
              )}
            </Card>
          </Card>
        )}
      </SimpleGrid>
      {storageService.getRole() === Roles.admin &&
        userDataDict &&
        authContext.user.email != null &&
        (obedienceBasedIsLoading ? (
          <Flex
            w="100%"
            h="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.500"
              size="xl"
            />
            <Text fontSize="xl" color="black" fontWeight="700" mb="5px">
              Loading...
            </Text>
          </Flex>
        ) : (
          <Card p="20px" alignItems="center" flexDirection="column" w="100%">
            <HStack justifyContent={"flex-end"}>
              <Button
                border="1px solid"
                borderColor="#6A53FF"
                onClick={handleExportResultsAsExcel}
              >
                <Icon
                  h="24px"
                  w="24px"
                  color="#6A53FF"
                  mr={2}
                  as={RiFileExcel2Fill}
                />
                Export Result Excel
              </Button>
            </HStack>

            <Table
              variant="simple"
              colorScheme="brand"
              w="100%"
              mt="20px"
              mb="20px"
            >
              <Thead>
                <Tr>
                  <Th>Answered By</Th>
                  <Th>Obedience</Th>
                  <Th>Name & Surname</Th>
                  <Th>Answered Option</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Object.keys(userDataDict).map((key: string, index: number) => (
                  <Tr key={index}>
                    <Td>{userDataDict[key].email}</Td>
                    <Td>{userDataDict[key].obedience}</Td>
                    <Td>{userDataDict[key].nameSurname}</Td>
                    <Td>
                      {
                        // Get the answered option from the question options
                        findAnswer(answersDict, userDataDict[key].id)
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>
        ))}
    </SimpleGrid>
  );
}

export default QuestionData;
