import { Box, Center, Spinner } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { IAnswer } from "constants/types";
import AnswersTable from "./components/AnswersTable";
import { getAllAnswers } from "services/AnswerService";

export default function Answers() {
  const [answerTableData, setAnswerTableData] = useState<IAnswer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  async function getUsers() {
    const allAnswers = await getAllAnswers();
    setAnswerTableData(allAnswers);
    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading ? (
        <Center>
          {" "}
          <Spinner />
        </Center>
      ) : (
        <AnswersTable tableData={answerTableData} />
      )}
    </Box>
  );
}
