import axios from "axios";
import Routes from "constants/constants";
import { IAnswer } from "constants/types";

export async function getAllAnswers(): Promise<IAnswer[]> {
  try {
    const response = await axios.get(Routes.getAllAnswers);
    const responseJson = await response.data;
    return responseJson.success ? (responseJson.data as IAnswer[]) : [];
  } catch (error) {
    return [];
  }
}
