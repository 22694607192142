import axios from "axios";
import Routes from "constants/constants";
import { ISmtpConfig } from "constants/types";

export async function getMailSmtp(): Promise<ISmtpConfig> {
  try {
    const response = await axios.get(Routes.getMailSmtp);
    const responseJson = response.data;
    if (responseJson.success) {
      return responseJson.data as ISmtpConfig;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export async function updateMailSmtp(smtpConfig: ISmtpConfig) {
  try {
    const response = await axios.post(Routes.updateMailSmtp, smtpConfig);
    const responseJson = response.data;
    if (responseJson.success) {
      return responseJson.data as ISmtpConfig;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function testMailSmtp(): Promise<boolean> {
  try {
    const response = await axios.get(Routes.testMailSmtp);
    const responseJson = response.data;
    return responseJson.success;
  } catch (error) {
    return false;
  }
}

export async function sendAuthMail(id: string): Promise<boolean> {
  try {
    const response = await axios.get(`${Routes.sendAuthMail}/${id}`);
    const responseJson = response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}

export async function sendMultipleAuthMail(ids: string[]): Promise<boolean> {
  try {
    const response = await axios.post(`${Routes.sendMultipleAuthMail}`, {
      ids,
    });
    const responseJson = response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}
