import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  List,
  ListItem,
} from "@chakra-ui/react";
import * as React from "react";
import { RiEdit2Fill, RiFileExcel2Fill } from "react-icons/ri";
// import * as XLSX from "xlsx";
import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import * as XLSX from "xlsx-js-style";
// Custom components
import Card from "components/card/Card";
import AddQuestion from "./AddQuestion";
import { IAdvancedUser, IQuestion } from "constants/types";
import { useEffect, useState } from "react";
import {
  changeActivityOfQuestion,
  deleteMultipleQuestions,
  deleteQuestion,
  sendStatistics,
} from "services/QuestionService";
import DeleteButtonWithDialog from "components/deleteButtonWithDialog/DeleteButtonWithDialog";
import { MdPieChartOutlined } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { ChevronDownIcon, DownloadIcon } from "@chakra-ui/icons";
import EditQuestion from "./EditQuestion";
import AddExtraTimeToQuestionModal from "./AddExtraTimeToQuestionModal";
import {
  getFirestore,
  doc as firestoreDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
const columnHelper = createColumnHelper<IQuestion>();

// const columns = columnsDataCheck;
export default function QuestionsTable(props: {
  tableData: any;
  getQuestions: () => Promise<void>;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [checkedRows, setCheckedRows] = React.useState<string[]>([]);
  const [isMultipleDeleteLoading, setIsMultipleDeleteLoading] =
    React.useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [questionIdToAddExtraTime, setQuestionIdToAddExtraTime] =
    useState<string>();
  const [totalReportsLoading, setTotalReportsLoading] = useState(false);
  const {
    isOpen: isActivityDialogOpen,
    onOpen: onActivityDialogOpen,
    onClose: onActivityDialogClose,
  } = useDisclosure();
  const {
    isOpen: isExtraCountdownDialogOpen,
    onOpen: onExtraCountdownDialogOpen,
    onClose: onExtraCountdownDialogClose,
  } = useDisclosure();
  const [cellInfo, setCellInfo] =
    useState<CellContext<IQuestion, string>>(null);

  const {
    isOpen: editQuestionIsOpen,
    onOpen: onOpenEditQuestion,
    onClose: onCloseEditQuestion,
  } = useDisclosure();

  useEffect(() => {
    setData(props.tableData);
  }, [props.tableData]);

  const toast = useToast();
  const cancelRef = React.useRef();

  async function removeQuestion(id: string): Promise<void> {
    const deleteResp = await deleteQuestion(id);
    if (deleteResp.success) {
      props.getQuestions();
    }
  }
  const history = useHistory();

  async function changeQuestionActivity(
    id: string,
    isActive: boolean
  ): Promise<void> {
    const respValue = await changeActivityOfQuestion(id, isActive);
    if (respValue) {
      toast({
        title: "Question status changed",
        description: "Question status changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      props.getQuestions();
    } else {
      toast({
        title: "Question status not changed",
        description: "Question status not changed successfully",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setCellInfo(null);
  }

  function dateFormatConverter(date: Date): string {
    // convert date to YY:MM:DD HH:MM:SS
    const dateObj = new Date(date);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const day = dateObj.getDate();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  async function handleDeleteMultipleQuestions() {
    setIsMultipleDeleteLoading(true);
    const deleteQuestionsResult = await deleteMultipleQuestions(checkedRows);
    if (deleteQuestionsResult) {
      toast({
        title: "Success",
        description: "Questions deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setCheckedRows([]);
    } else {
      toast({
        title: "Error",
        description: "Questions not deleted successfully",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    props.getQuestions();
    setIsMultipleDeleteLoading(false);
  }

  async function sendStatisticsToClient(id: string): Promise<void> {
    const sendResult = await sendStatistics(id);
    if (sendResult) {
      toast({
        title: "Voting Results On Air",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      props.getQuestions();
    } else {
      toast({
        title: "Statistics not sent",
        description: "Statistics not sent successfully",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }

  async function calculateForObedience(
    answersDict: {
      [key: string]: string[];
    },
    question: IQuestion
  ) {
    const obedienceBasedAnswersDict: {
      [key: number]: number;
    } = {};
    const userDict: {
      [key: string]: number;
    } = {};

    let users: IAdvancedUser[] = [];
    const userIds = Object.values(answersDict).flat();
    // Get all users by userIds without onSnapshot
    const db = getFirestore();

    const promises = userIds.map(async (userId) => {
      const docRef = firestoreDoc(db, "users", userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as IAdvancedUser;
      } else {
        console.log("No such document!");
      }
    });
    users = await Promise.all(promises);

    users.forEach((user) => {
      userDict[user.id] = user.obedienceVoteWeight;
    });

    question.options.forEach((option, index) => {
      obedienceBasedAnswersDict[index] = 0;
    });

    Object.keys(answersDict).forEach((answerDictKey: string) => {
      const key = parseInt(answerDictKey);
      let calculatedUserIds: string[] = [];
      // the reduce function takes two parameters: a function, and an initial value
      obedienceBasedAnswersDict[key] = answersDict[key].reduce(
        // the function takes two parameters: the accumulator and the current value
        (acc, curr) => {
          if (!calculatedUserIds.includes(curr)) {
            calculatedUserIds.push(curr);
            return acc + userDict[curr];
          } else {
            // dont add the obedienceVoteWeight if the user has already been calculated
            return acc;
          }
        },
        // the initial value is 0
        0
      );
    });

    return Object.values(obedienceBasedAnswersDict).map((answer) => answer);
  }

  async function handleTotalAnswerReportsExcel() {
    setTotalReportsLoading(true);
    const totalReportsData: {
      id: string;
      answers: number[];
      obedienceBasedAnswers: number[];
      pieChartData: number[];
      answersDict: {
        [key: number]: string[];
      };
    }[] = [];

    const db = getFirestore();
    const questionIds: string[] = props.tableData.map(
      (item: IQuestion) => item.id
    );
    const questionData: IQuestion[] = [];

    await Promise.all(
      questionIds.map(async (id) => {
        const questionDoc = firestoreDoc(db, "questions", id);
        const questionRef = await getDoc(questionDoc);
        if (questionRef.exists()) {
          questionData.push(questionRef.data() as IQuestion);
        }
      })
    );

    await Promise.all(
      questionData.map(async (question) => {
        // get answers of question from firestore answers collection
        const answersRef = collection(db, "answers");
        const q = query(answersRef, where("questionId", "==", question.id));
        await getDocs(q).then(async (querySnapshot) => {
          const answersDict: {
            [key: number]: string[];
          } = {};
          question.options.forEach((option, index) => {
            answersDict[index] = [];
          });
          const answers: any = [];
          await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
              // Get Answered user data from users collection based on answeredBy
              const userAnswered = (
                await getDoc(docRef)
              ).data() as IAdvancedUser;
              answersDict[doc.data().answerIndex] = [
                ...(answersDict[doc.data().answerIndex] || []),
                // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
                ...Array(userAnswered.voteWeight || 1).fill(
                  doc.data().answeredBy
                ),
              ];
              answers.push(doc.data().answeredBy);
            })
          );
          // setAnswers(answers);
          // setAnswersDict(answersDict);

          const obedienceBasedAnswers = await calculateForObedience(
            answersDict,
            question
          );
          const pieChartData = Object.values(answersDict).map(
            (answer) => answer.length
          );
          const data = {
            id: question.id,
            answers: answers,
            pieChartData: pieChartData,
            answersDict: answersDict,
            obedienceBasedAnswers: obedienceBasedAnswers,
          };
          totalReportsData.push(data);
        });
      })
    );
    const wb = XLSX.utils.book_new();
    const wsArr: XLSX.WorkSheet[] = [];
    questionData.forEach((question, index) => {
      const reportDataToSelect = totalReportsData.filter(
        (item) => item.id === question.id
      )[0];
      const tableData = [
        [
          "ID",
          "Question Title",
          "Created At",
          "Number of Vote Casts",
          // options col for each question options but take the option length
          ...question.options.map((option) => `${option.title} (Member Vote)`),
          ...question.options.map(
            (option) => `${option.title} (Obediences Vote)`
          ),
        ],
        [
          question.id,
          question.questionText,
          question.createdAt,
          reportDataToSelect?.answers.length ?? 0,
          ...question.options.map((option, subIndex) => {
            const noOfVotes = reportDataToSelect?.pieChartData[subIndex] ?? 0;
            const percentageOfVote =
              (
                (reportDataToSelect.pieChartData[subIndex] /
                  // PieChart data values sum
                  (reportDataToSelect.pieChartData.reduce((a, b) => a + b, 0) ||
                    1)) *
                100
              ).toFixed(2) || 0;
            return `${noOfVotes} Votes  - %  ${percentageOfVote}`;
          }),
          ...question.options.map((option, subIndex) => {
            const noOfVotes =
              reportDataToSelect?.obedienceBasedAnswers[subIndex] ?? 0;
            const percentageOfVote =
              (
                (reportDataToSelect.obedienceBasedAnswers[subIndex] /
                  // obedienceBasedAnswers data values sum
                  (reportDataToSelect.obedienceBasedAnswers.reduce(
                    (a, b) => a + b,
                    0
                  ) || 1)) *
                100
              ).toFixed(2) || 0;
            return `${noOfVotes} Votes  - %  ${percentageOfVote}`;
          }),
        ],
      ];

      const ws = XLSX.utils.aoa_to_sheet(tableData);
      const noOfColumns: number = question.options.length * 2 + 4;
      ws["!cols"] = Array(noOfColumns).fill({ wch: 45 });
      wsArr.push(ws);
    });

    wsArr.forEach((ws, index) => {
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `question-${questionData[index].id + 1}`
      );
    });

    // XLSX.utils.book_append_sheet(wb, ws, "total_reports");
    XLSX.writeFile(wb, "total_reports.xlsx");
    setTotalReportsLoading(false);
  }

  function handleDownloadExcelButtonClick() {
    const tableData = [
      ["ID", "Question Title", "Options", "Created By", "Created At"],
      ...data.map((item) => [
        item.id,
        item.questionText,
        item.options
          .map((option: { title: string }) => option.title)
          .join(", "),
        item.createdByUser,
        item.createdAt,
      ]),
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(tableData);

    // Make column widths larger
    ws["!cols"] = [
      { wch: 35 },
      { wch: 35 },
      { wch: 30 },
      { wch: 35 },
      { wch: 40 },
    ];

    XLSX.utils.book_append_sheet(wb, ws, "Questions");
    XLSX.writeFile(wb, "Questions.xlsx");
  }

  async function newExcelDownload() {
    // create a new excel for all question reports in same table
    // columns must be dynamic based on question options
    // each question must be in a new sheet
    // Columns must be Vote Date - Question - Number of Vote Casts - In line with the equally weighted votes (inluding proxies) -  In line with the voting rights of the Obedience (inluding proxies) - Question ID - Question TimeStamp
    // setTotalReportsLoading(true);
    const totalReportsData: {
      id: string;
      answers: number[];
      obedienceBasedAnswers: number[];
      pieChartData: number[];
      answersDict: {
        [key: number]: string[];
      };
      options: {
        title: string;
      }[];
    }[] = [];

    const db = getFirestore();
    const questionIds: string[] = props.tableData.map(
      (item: IQuestion) => item.id
    );
    const questionData: IQuestion[] = [];

    await Promise.all(
      questionIds.map(async (id) => {
        const questionDoc = firestoreDoc(db, "questions", id);
        const questionRef = await getDoc(questionDoc);
        if (questionRef.exists()) {
          questionData.push(questionRef.data() as IQuestion);
        }
      })
    );

    await Promise.all(
      questionData.map(async (question) => {
        // get answers of question from firestore answers collection
        const answersRef = collection(db, "answers");
        const q = query(answersRef, where("questionId", "==", question.id));
        await getDocs(q).then(async (querySnapshot) => {
          const answersDict: {
            [key: number]: string[];
          } = {};
          question.options.forEach((option, index) => {
            answersDict[index] = [];
          });
          const answers: any = [];
          await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const docRef = firestoreDoc(db, "users", doc.data().answeredBy);
              // Get Answered user data from users collection based on answeredBy
              const userAnswered = (
                await getDoc(docRef)
              ).data() as IAdvancedUser;
              answersDict[doc.data().answerIndex] = [
                ...(answersDict[doc.data().answerIndex] || []),
                // Make a array of same userId(answeredBy) by the times of userAnswered.voteCount
                ...Array(userAnswered.voteWeight || 1).fill(
                  doc.data().answeredBy
                ),
              ];
              answers.push(doc.data().answeredBy);
            })
          );
          // setAnswers(answers);
          // setAnswersDict(answersDict);

          const obedienceBasedAnswers = await calculateForObedience(
            answersDict,
            question
          );
          const pieChartData = Object.values(answersDict).map(
            (answer) => answer.length
          );
          const data = {
            id: question.id,
            answers: answers,
            options: question.options,
            pieChartData: pieChartData,
            answersDict: answersDict,
            obedienceBasedAnswers: obedienceBasedAnswers,
          };
          totalReportsData.push(data);
        });
      })
    );

    // const tableData = [
    //   "Question",
    //   "Number of Vote Casts",
    //   "In line with the equally weighted votes (inluding proxies)",
    //   "In line with the voting rights of the Obedience (inluding proxies)",
    //   "Question ID",
    //   "Question TimeStamp",
    //   ...questionData.map((item) => {
    //     const reportDataToSelect = totalReportsData.filter(
    //       (question) => item.id === question.id
    //     )[0];
    //     return [
    //       item.questionText,
    //       reportDataToSelect?.answers.length ?? 0,
    //       item.createdAt,
    //     ];
    //   }),
    // ];

    const wb = XLSX.utils.book_new();

    console.log("reportDataToSelect", totalReportsData);
    const tableData = [
      [
        "Question ID",
        "Question",
        "Number of Answered Users",
        "Total Number Of Votes (Including Proxies)",
        ...Array.from(Array(10).keys()).map(
          (item) =>
            `Option ${
              item + 1
            } \n In line with the equally weighted votes (inluding proxies)`
        ),
        ...Array.from(Array(10).keys()).map(
          (item) =>
            `Option ${
              item + 1
            } \n In line with the voting rights of the Obedience
            (inluding proxies)`
        ),
        "Question TimeStamp",
      ],
      ...questionData.map((item) => {
        const reportDataToSelect = totalReportsData.filter(
          (question) => item.id === question.id
        )[0];
        return [
          item.id,
          item.questionText,
          reportDataToSelect?.answers.length ?? 0,
          reportDataToSelect.pieChartData.reduce((a, b) => a + b, 0),
          ...Array.from(Array(10).keys()).map(
            (item) =>
              `${reportDataToSelect.options[item]?.title ?? "No Option"} - ${
                reportDataToSelect?.answersDict[item]?.length ?? 0
              }`
          ),
          ...Array.from(Array(10).keys()).map(
            (item) =>
              `${reportDataToSelect.options[item]?.title ?? "No Option"} - ${
                reportDataToSelect?.obedienceBasedAnswers[item] ?? 0
              }`
          ),
          dateFormatConverter(item.createdAt),
        ];
      }),
    ];

    const ws = XLSX.utils.aoa_to_sheet(tableData);

    // Make each row height 50
    ws["!rows"] = questionData.map((item) => {
      return { hpx: 150 };
    });

    // Make each column width 50
    ws["!cols"] = Array.from(Array(questionData.length).keys()).map((item) => {
      return { wpx: 100 };
    });

    // iterate over ws cells and set font size
    Object.keys(ws).forEach((key) => {
      if (key[0] === "!") return;
      ws[key].s = {
        alignment: {
          wrapText: true,
          horizontal: "center",
          vertical: "center",
        },
      };
    });

    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      if (rowNum % 2 === 0) {
        // iterate over each cell in this row
        for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
          // get the cell address
          var cellAddress = { c: colNum, r: rowNum };

          // find the cell
          var cellRef = XLSX.utils.encode_cell(cellAddress);
          var cell = ws[cellRef];

          // append the font size to the cell
          Object.assign(cell.s, {
            fill: { fgColor: { rgb: "E0EBF6" } },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } },
            },
          });
        }
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report.xlsx");
    setTotalReportsLoading(false);
  }

  function handleSelect() {
    checkedRows.length > 0
      ? setCheckedRows([])
      : setCheckedRows(data.map((question) => question.id));
  }

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          ID
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Checkbox
            colorScheme="brandScheme"
            // checked={checkedRows.includes(info.renderValue())}
            isChecked={checkedRows.includes(info.renderValue())}
            value={info.renderValue()}
            onChange={(e) => {
              if (e.target.checked && !checkedRows.includes(e.target.value)) {
                setCheckedRows([...checkedRows, e.target.value]);
              } else {
                setCheckedRows(
                  checkedRows.filter((row) => row !== e.target.value)
                );
              }
            }}
            me="10px"
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("questionText", {
      id: "questionText",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Question Title
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("options", {
      id: "options",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Options
        </Text>
      ),
      cell: (info) =>
        // List of options
        info.getValue().map((option: { title: string }) => (
          <List spacing={3} key={option.title}>
            <ListItem>
              <Text color={textColor} fontSize="sm" fontWeight="700">
                - {option.title}
              </Text>
            </ListItem>
          </List>
        )),
    }),

    columnHelper.accessor("isActive", {
      id: "isActive",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Is Active
        </Text>
      ),
      cell: (info) => (
        <Text
          color={info.getValue().valueOf() ? "green.500" : "red.500"}
          fontSize="sm"
          fontWeight="700"
        >
          {info.getValue().valueOf() ? "Yes" : "No"}
        </Text>
      ),
    }),
    columnHelper.accessor("showStats", {
      id: "showStats",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Show Stats
        </Text>
      ),
      cell: (info) => (
        <Text
          color={info.getValue()?.valueOf() ? "green.500" : "red.500"}
          fontSize="sm"
          fontWeight="700"
        >
          {info.getValue()?.valueOf() ? "Yes" : "No"}
        </Text>
      ),
    }),
    columnHelper.accessor("createdByUser", {
      id: "createdByUser",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Created By
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ? info.getValue().email : "No Email"}
        </Text>
      ),
    }),
    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Created At
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    // Add new column but only one delete action button
    columnHelper.accessor("id", {
      id: "delete",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => {
        return (
          //
          <HStack>
            <IconButton
              aria-label="See Statistics"
              icon={<Icon as={MdPieChartOutlined} />}
              colorScheme="brandScheme"
              onClick={() => {
                // get full question from helper
                history.push({
                  pathname: `/admin/question-stats/${info.getValue()}`,
                  state: { questionData: info.row.original },
                });
              }}
            />
            <IconButton
              aria-label="Edit"
              icon={<Icon as={RiEdit2Fill} />}
              colorScheme="brandScheme"
              onClick={() => {
                setCellInfo(info);
                onOpenEditQuestion();
              }}
            ></IconButton>
            <DeleteButtonWithDialog
              title="Delete Question"
              description="Are you sure you want to delete this question?"
              onConfirm={async () => {
                await removeQuestion(info.getValue());
              }}
            />

            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton isActive={isOpen} as={Button}>
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={async () => {
                        if (info.row.original.isActive) {
                          changeQuestionActivity(
                            info.getValue(),
                            !info.row.original.isActive
                          );
                          onClose();
                        } else {
                          setCellInfo(info);
                          onActivityDialogOpen();
                        }
                      }}
                    >
                      {info.row.original.isActive
                        ? "Deactivate Question"
                        : "Activate Question"}
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        sendStatisticsToClient(info.getValue());
                      }}
                    >
                      {info.row.original.showStats ? "Hide" : "Show"} Voting
                      Results
                    </MenuItem>
                    <MenuItem
                      onClick={async () => {
                        setQuestionIdToAddExtraTime(info.getValue());
                        onExtraCountdownDialogOpen();
                      }}
                    >
                      Add Extra Countdown Time to Question
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </HStack>
        );
      },
    }),
  ];
  const [data, setData] = useState<IQuestion[]>([...props.tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const tableRef = React.useRef(null);
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", "2xl": "hidden" }}
    >
      <Modal
        isOpen={editQuestionIsOpen}
        onClose={onCloseEditQuestion}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            {editQuestionIsOpen && (
              <EditQuestion
                onModalClose={onCloseEditQuestion}
                getQuestions={props.getQuestions}
                questionToUpdate={cellInfo ? cellInfo.row.original : null}
                resetQuestionToUpdate={() => {
                  setCellInfo(null);
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          mb="4px"
          fontWeight="700"
          lineHeight="100%"
        >
          Questions Table
        </Text>

        <HStack>
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            isLoading={totalReportsLoading}
            disabled={totalReportsLoading}
            // onClick={handleTotalAnswerReportsExcel}
            onClick={newExcelDownload}
          >
            {" "}
            <Icon h="24px" w="24px" color="#6A53FF" mr={2} as={DownloadIcon} />
            Total Answer Reports
          </Button>
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            onClick={handleDownloadExcelButtonClick}
          >
            {" "}
            <Icon
              h="24px"
              w="24px"
              color="#6A53FF"
              mr={2}
              as={RiFileExcel2Fill}
            />
            Export Excel
          </Button>
          <AddExtraTimeToQuestionModal
            isOpen={isExtraCountdownDialogOpen}
            onClose={() => {
              onExtraCountdownDialogClose();
              setQuestionIdToAddExtraTime(null);
              props.getQuestions();
            }}
            onOpen={onExtraCountdownDialogOpen}
            questionId={questionIdToAddExtraTime}
          />

          <Button border="1px solid" borderColor="#6A53FF" onClick={onOpen}>
            Add New Question
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Question Modal</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {isOpen && (
                  <AddQuestion
                    onModalClose={onClose}
                    getQuestions={props.getQuestions}
                  />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </HStack>
      </Flex>
      <Flex px="25px" mt="8px" justifyContent="space-between" align="center">
        <HStack>
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            onClick={handleSelect}
          >
            {checkedRows.length > 0 ? "Deselect All" : "Select All"}
          </Button>
        </HStack>
        {checkedRows.length > 0 && (
          <HStack>
            <Button
              border="1px solid"
              colorScheme={"red"}
              isLoading={isMultipleDeleteLoading}
              disabled={isMultipleDeleteLoading}
              onClick={handleDeleteMultipleQuestions}
            >
              Delete Selected Questions
            </Button>
          </HStack>
        )}
      </Flex>
      <Box>
        <Table
          variant="simple"
          color="gray.500"
          mb="24px"
          mt="12px"
          ref={tableRef}
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table
              .getRowModel()
              .rows.slice(0, data.length)
              .map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor="transparent"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
        <AlertDialog
          isOpen={isActivityDialogOpen}
          leastDestructiveRef={cancelRef}
          onClose={onActivityDialogClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                The Question will be on air. Are you sure ?
              </AlertDialogHeader>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onActivityDialogClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={async () => {
                    await changeQuestionActivity(
                      cellInfo.getValue(),
                      !cellInfo.row.original.isActive
                    );
                    onActivityDialogClose();
                  }}
                  ml={3}
                >
                  Activate
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Card>
  );
}
