const BASE_URL = "https://us-central1-clipsas-poll.cloudfunctions.net/api";
// const BASE_URL = "http://127.0.0.1:5001/clipsas-poll/us-central1/api";

const Routes = {
  adminLogin: `${BASE_URL}/users/adminLogin`,
  addQuestion: `${BASE_URL}/questions/create`,
  updateQuestion: `${BASE_URL}/questions/update`,
  getAllQuestions: `${BASE_URL}/questions`,
  getAllUsers: `${BASE_URL}/users`,
  deleteUser: `${BASE_URL}/users`,
  getAllAnswers: `${BASE_URL}/answers`,
  getReports: `${BASE_URL}/dashboard/panelData`,
  createUser: `${BASE_URL}/users/create`,
  createMultipleUsers: `${BASE_URL}/users/createMultiple`,
  updateUser: `${BASE_URL}/users/update`,
  changeUserRole: `${BASE_URL}/users/changeRole`,
  deleteQuestion: `${BASE_URL}/questions`,
  getSingleQuestion: `${BASE_URL}/questions/single`,
  addCountdownTimeToQuestion: `${BASE_URL}/questions/addCountdownTime`,
  getMailSmtp: `${BASE_URL}/mail`,
  updateMailSmtp: `${BASE_URL}/mail`,
  testMailSmtp: `${BASE_URL}/mail/verify`,
  getAdvancedUser: `${BASE_URL}/users/advanced`,
  changeActivityOfQuestion: `${BASE_URL}/questions/changeActivity`,
  sendAuthMail: `${BASE_URL}/mail/sendAuthMail`,
  sendMultipleAuthMail: `${BASE_URL}/mail/sendMultiple`,
  sendStatistics: `${BASE_URL}/questions/sendStatistics`,
  getQuestionStatistics: `${BASE_URL}/questions/statistics`,
  deleteMultipleQuestions: `${BASE_URL}/questions/deleteMultiple`,
  deleteMultipleUsers: `${BASE_URL}/users/removeMultiple`,
  getTotalNumberOfUsersWhoCanVote: `${BASE_URL}/questions/totalNumberOfUsersWhoCanVote`,
};

export default Routes;
