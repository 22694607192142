import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useToast,
} from "@chakra-ui/react";
import { CountdownUnits } from "constants/enums";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { values } from "lodash";
import { addCountdownTimeToQuestion } from "services/QuestionService";
import * as yup from "yup";

export default function AddExtraTimeToQuestionModal(addExtraTimeToQuestionProps: {
  questionId: string;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  const initialValues = {
    countdownTimeValue: 5,
    countdownTimeUnit: CountdownUnits.minutes,
  };
  const toast = useToast();

  const validationSchema = yup.object().shape({
    countdownTimeValue: yup
      .number()
      .required("Required!")
      .min(1, "Must be greater than 0")
      .max(60, "Must be less than 60"),
    countdownTimeUnit: yup
      .number()
      .required("Required!")
      .min(1, "Must be valid unit")
      .max(3, "Must be valid unit"),
  });

  const submitHandler = async (values: any, actions: any) => {
    try {
      actions.setSubmitting(true);
      let addExtraTimeResp = await addCountdownTimeToQuestion(
        addExtraTimeToQuestionProps.questionId,
        values.countdownTimeValue,
        values.countdownTimeUnit
      );
    } catch (error: any) {
      toast({
        title: "Error",
        description:
          error?.message ??
          "There was an error adding extra time to the question",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      toast({
        title: "Extra Time Added",
        description: "The extra time has been added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      actions.setSubmitting(false);
      addExtraTimeToQuestionProps.onClose();
    }
  };

  return (
    <Modal
      isOpen={addExtraTimeToQuestionProps.isOpen}
      onClose={addExtraTimeToQuestionProps.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Extra Time To Question</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <Field name="extraTime">
                  {({ field, form }: { field: any; form: any }) => (
                    <FormControl>
                      <Box display="flex" flexDirection="row">
                        <FieldArray {...field} name="extraTime" id="extraTime">
                          {(fieldArrayProps) => {
                            const { form } = fieldArrayProps;
                            const { values, errors, touched } = form;
                            const errorHandler = (name: any) => {
                              const error = getIn(errors, name);
                              const touch = getIn(touched, name);
                              return touch && error ? error : null;
                            };
                            return (
                              <Flex direction="row" mt={4} w={"100%"}>
                                <FormControl
                                  isInvalid={errorHandler(`countdownTimeValue`)}
                                  id="countdownTimeValue"
                                >
                                  <FormLabel htmlFor={`countdownTimeValue`}>
                                    Countdown Time Value:
                                  </FormLabel>
                                  <NumberInput
                                    defaultValue={values.countdownTimeValue}
                                    min={1}
                                    max={60}
                                    value={values.countdownTimeValue || 1}
                                    onChange={(value) => {
                                      form.setFieldValue(
                                        "countdownTimeValue",
                                        Number(value)
                                      );
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                  <FormErrorMessage>
                                    {errorHandler(`countdownTimeValue`)}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  isInvalid={errorHandler(`countdownTimeUnit`)}
                                  id="countdownTimeUnit"
                                  ml={4}
                                >
                                  <FormLabel htmlFor={`countdownTimeUnit`}>
                                    Countdown Time Unit:
                                  </FormLabel>
                                  <Select
                                    placeholder="Select Time Unit"
                                    value={values.countdownTimeUnit}
                                    onChange={(e) => {
                                      form.setFieldValue(
                                        "countdownTimeUnit",
                                        Number(e.target.value)
                                      );
                                    }}
                                  >
                                    {Object.values(CountdownUnits)
                                      .filter((v) => isNaN(Number(v)))
                                      .map((unit, index) => {
                                        return (
                                          <option
                                            id={unit.toString()}
                                            value={
                                              // numberic value of the enum
                                              index + 1
                                            }
                                          >
                                            {unit}
                                          </option>
                                        );
                                      })}
                                  </Select>
                                  <FormErrorMessage>
                                    {errorHandler(`countdownTimeUnit`)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>
                            );
                          }}
                        </FieldArray>
                      </Box>
                    </FormControl>
                  )}
                </Field>
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  isLoading={props.isSubmitting}
                  type="submit"
                  disabled={!(props.isValid && props.dirty)}
                >
                  Save Changes
                </Button>
                <Button
                  variant="ghost"
                  isLoading={props.isSubmitting}
                  onClick={addExtraTimeToQuestionProps.onClose}
                >
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}
