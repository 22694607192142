export enum ILodgeUnits {
  // Büyük Loca
  bigLodge = "Big Lodge",
  // Bölge Locası
  regionalLodge = "Regional Lodge",
  // Yöre Başkanlığı
  regionalPresidency = "Regional Presidency",
  // Loca
  lodge = "Lodge",
}

export enum CountdownUnits {
  minutes = 1,
  hour,
  day,
}

export enum Roles {
  admin = "admin",
  organizator = "organizator",
  user = "user",
}
