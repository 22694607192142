import { Box, Center, Spinner } from "@chakra-ui/react";

import QuestionsTable from "./components/QuestionsTable";
import { useEffect, useState } from "react";
import { IQuestion } from "constants/types";
import { getAllQuestions } from "services/QuestionService";

export default function Questions() {
  const [questionTableData, setQuestionTableData] = useState<IQuestion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function getQuestions() {
    const allQuestions = await getAllQuestions();
    setQuestionTableData(allQuestions);
    setIsLoading(false);
  }

  useEffect(() => {
    getQuestions();
  }, []);
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading ? (
        <Center>
          {" "}
          <Spinner />
        </Center>
      ) : (
        <QuestionsTable
          tableData={isLoading ? [] : questionTableData}
          getQuestions={getQuestions}
        />
      )}
    </Box>
  );
}
