import { Redirect, Route, Switch } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import { storageService } from "services";
import { useContext, useEffect } from "react";
import { AuthContext } from "services/AuthProvider";
import axios from "axios";

function App() {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + storageService.getToken();
  const authContext = useContext(AuthContext);
  useEffect(() => {
    authContext.auth.onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken(true).then((token) => {
          storageService.saveToken(token);
          // storageService.setUser(user);
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        });
      } else {
        storageService.cleanLocaleStorage();
        authContext.auth.signOut();
      }
    });
  }, [authContext.user]);

  if (authContext.user == null) {
    return (
      <div className="App">
        <Switch>
          <Route path={`/auth`} component={AuthLayout} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Switch>
          <Route path={`/admin`} component={AdminLayout} />
          <Redirect to="/admin" />
        </Switch>
      </div>
    );
  }
}

export default App;
