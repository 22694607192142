// Chakra imports
import { Button, Flex, useColorModeValue } from "@chakra-ui/react";
// Assets
import { useDropzone } from "react-dropzone";

function ExcelDropzone(props: {
  content: JSX.Element | string;
  handleImport: (files: File[], event: any) => void;
  [x: string]: any;
}) {
  const { content, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);
    },
    onDropAccepted(files, event) {
      props.handleImport(files as File[], event);
    },
  });

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      h="max-content"
      minH="100%"
      cursor="pointer"
      {...getRootProps({ className: "dropzone" })}
      {...rest}
    >
      <input
        {...getInputProps()}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={() => {}}
      />
      <Button variant="no-effects">{content}</Button>
    </Flex>
  );
}

export default ExcelDropzone;
