import {
  Flex,
  Box,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  Icon,
  HStack,
  Modal,
  IconButton,
  useToast,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import * as React from "react";
import * as XLSX from "xlsx";
import debounce from "lodash.debounce";
import { RiEdit2Fill, RiFileExcel2Fill } from "react-icons/ri";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
// Custom components
import Card from "components/card/Card";
import { IAdvancedUser, IUser } from "constants/types";
import { AddUser } from "./AddUser";
import UploadExcel from "./UploadExcel";
import { useEffect } from "react";
import DeleteButtonWithDialog from "components/deleteButtonWithDialog/DeleteButtonWithDialog";
import {
  deleteMultipleUsers,
  deleteUser,
  getAdvancedUser,
} from "services/UserService";
import { EditUser } from "./EditUser";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { sendAuthMail, sendMultipleAuthMail } from "services/MailService";
import { storageService } from "services";
import ChangeUserRoleModal from "./ChangeUserRole";
import { Roles } from "constants/enums";

const columnHelper = createColumnHelper<IUser>();

// const columns = columnsDataCheck;
export default function UsersTable(props: {
  tableData: IUser[];
  getUsers: () => Promise<void>;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [checkedRows, setCheckedRows] = React.useState<string[]>([]);
  const [userToEdit, setUserToEdit] = React.useState<IAdvancedUser>();
  const [userToChangeRole, setUserToChangeRole] = React.useState<{
    id: string;
    role: Roles;
  }>();
  const [isEditUserLoading, setIsEditUserLoading] = React.useState(false);
  const [isSendEmailLoading, setIsSendEmailLoading] = React.useState(false);
  const [isDeleteMultipleUsersLoading, setIsDeleteMultipleUsersLoading] =
    React.useState(false);
  const {
    isOpen: excelIsOpen,
    onOpen: onOpenExcel,
    onClose: onCloseExcel,
  } = useDisclosure();
  const {
    isOpen: changeUserRoleIsOpen,
    onOpen: onOpenChangeUserRole,
    onClose: onCloseChangeUserRole,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: editUserIsOpen,
    onOpen: onOpenEditUser,
    onClose: onCloseEditUser,
  } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    setData(props.tableData);
  }, [props.tableData]);

  async function handleEditUser(id: string) {
    const editUser = await getAdvancedUser(id);
    if (editUser) {
      setUserToEdit(editUser);
      onOpenEditUser();
    } else {
      toast({
        title: "Error",
        description: "Error getting user data",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  async function sendAuthMailToUser(id: string) {
    const sendMailResult = await sendAuthMail(id);
    if (sendMailResult) {
      toast({
        title: "Success",
        description: "Mail sent",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "Error sending mail",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }

  function searchUserFilter(query: string) {
    if (!query) return setData(props.tableData);
    const debouncedSearch = debounce(() => {
      const filteredData = props.tableData.filter((user) => {
        return (
          user.displayName.toLowerCase().includes(query.toLowerCase()) ||
          user.email.toLowerCase().includes(query.toLowerCase()) ||
          user.uid === query
        );
      });
      setData(filteredData);
    }, 500);

    debouncedSearch();
  }

  function handleSelect() {
    checkedRows.length > 0
      ? setCheckedRows([])
      : setCheckedRows(data.map((user) => user.uid));
  }

  async function sendMultipleMail() {
    setIsSendEmailLoading(true);
    const sendMailResult = await sendMultipleAuthMail(checkedRows);
    if (sendMailResult) {
      toast({
        title: "Success",
        description: "Mails sent to selected users",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setCheckedRows([]);
    } else {
      toast({
        title: "Error",
        description: "Error sending mail",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsSendEmailLoading(false);
  }

  async function handleDeleteMultipleUsers() {
    setIsDeleteMultipleUsersLoading(true);
    const deleteUsersResult = await deleteMultipleUsers(checkedRows);
    if (deleteUsersResult) {
      toast({
        title: "Success",
        description: "Users deleted",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setCheckedRows([]);
    } else {
      toast({
        title: "Error",
        description: "Error deleting users",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setCheckedRows([]);
    }
    props.getUsers();
    setIsDeleteMultipleUsersLoading(false);
  }

  const columns = [
    columnHelper.accessor("uid", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          ID
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Checkbox
            colorScheme="brandScheme"
            // checked={checkedRows.includes(info.renderValue())}
            isChecked={checkedRows.includes(info.renderValue())}
            value={info.renderValue()}
            onChange={(e) => {
              if (e.target.checked && !checkedRows.includes(e.target.value)) {
                setCheckedRows([...checkedRows, e.target.value]);
              } else {
                setCheckedRows(
                  checkedRows.filter((row) => row !== e.target.value)
                );
              }
            }}
            me="10px"
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("displayName", {
      id: "displayName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("role", {
      id: "role",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Role
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("advancedUser", {
      id: "advancedUser",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Obedience
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() ? info.getValue().obedience : "No Obedience"}
        </Text>
      ),
    }),
    columnHelper.accessor("lastSignInTime", {
      id: "lastSignInTime",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Last Signed In
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()
            ? new Date(info.getValue()).toLocaleDateString()
            : "Never"}
        </Text>
      ),
    }),
    columnHelper.accessor("uid", {
      id: "delete",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => {
        return (
          //
          <HStack>
            <IconButton
              aria-label="Edit"
              icon={<Icon as={RiEdit2Fill} />}
              colorScheme="brandScheme"
              disabled={info.row.original.role !== "user"}
              isLoading={isEditUserLoading}
              onClick={() => {
                handleEditUser(info.getValue());
              }}
            ></IconButton>
            <DeleteButtonWithDialog
              title="Delete User"
              description="Are you sure you want to delete this user?"
              onConfirm={async () => {
                await deleteUser(info.getValue());
                await props.getUsers();
              }}
            />
            <Menu>
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton isActive={isOpen} as={Button}>
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      disabled={info.row.original.role !== "user"}
                      onClick={async () => {
                        sendAuthMailToUser(info.getValue());
                        onClose();
                      }}
                    >
                      Send Auth Email
                    </MenuItem>
                    {storageService.getRole() === "admin" && (
                      <MenuItem
                        onClick={async () => {
                          setUserToChangeRole({
                            id: info.getValue(),
                            role: info.row.original.role as Roles,
                          });
                          onOpenChangeUserRole();
                        }}
                      >
                        Change User Role
                      </MenuItem>
                    )}
                  </MenuList>
                </>
              )}
            </Menu>
          </HStack>
        );
      },
    }),
  ];
  const [data, setData] = React.useState(() => [...props.tableData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const tableRef = React.useRef(null);

  function handleDownloadButtonClick() {
    // Define your table data as an array of arrays
    const tableData = [
      ["Name", "Email", "Role", "Obedience", "Last Signed In"],
      ...data.map((user) => [
        user.displayName,
        user.email,
        user.role,
        user.advancedUser ? user.advancedUser.obedience : "No Obedience",
        user.lastSignInTime
          ? new Date(user.lastSignInTime).toLocaleDateString()
          : "Never",
      ]),
    ];

    // Create a new workbook and add a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(tableData);
    // Make column widths larger
    worksheet["!cols"] = [
      { wch: 20 },
      { wch: 30 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Export the workbook as an Excel file and download it
    XLSX.writeFile(workbook, "table-data.xlsx");
  }

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", "2xl": "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <HStack>
          <Text
            color={textColor}
            fontSize="22px"
            mb="4px"
            fontWeight="700"
            lineHeight="100%"
          >
            Users Table
          </Text>
          <Input
            placeholder="Search by name, email or id"
            onChange={(e) => {
              searchUserFilter(e.target.value);
            }}
          />
        </HStack>
        <HStack>
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            onClick={onOpenExcel}
          >
            <Icon
              h="24px"
              w="24px"
              color="#6A53FF"
              mr={2}
              as={RiFileExcel2Fill}
            />
            Import CSV
          </Button>
          {excelIsOpen && (
            <UploadExcel
              isOpen={excelIsOpen}
              onClose={() => {
                onCloseExcel();
                props.getUsers();
              }}
              onOpen={onOpenExcel}
            />
          )}
          <ChangeUserRoleModal
            isOpen={changeUserRoleIsOpen}
            onClose={() => {
              onCloseChangeUserRole();
              setUserToChangeRole(null);
              props.getUsers();
            }}
            onOpen={onOpenChangeUserRole}
            user={userToChangeRole}
          />
          {/* <DownloadTableExcel
            filename="Users Table"
            sheet="users"
            currentTableRef={tableRef.current}
          > */}
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            onClick={handleDownloadButtonClick}
          >
            <Icon
              h="24px"
              w="24px"
              color="#6A53FF"
              mr={2}
              as={RiFileExcel2Fill}
            />
            Export Excel
          </Button>
          {/* </DownloadTableExcel> */}

          <Button border="1px solid" borderColor="#6A53FF" onClick={onOpen}>
            Add New User
          </Button>
          <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              <ModalBody>
                {isOpen && (
                  <AddUser onModalClose={onClose} getUsers={props.getUsers} />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </HStack>
      </Flex>
      <Flex px="25px" mt="8px" justifyContent="space-between" align="center">
        <HStack>
          <Button
            border="1px solid"
            borderColor="#6A53FF"
            onClick={handleSelect}
          >
            {checkedRows.length > 0 ? "Deselect All" : "Select All"}
          </Button>
        </HStack>
        {checkedRows.length > 0 && (
          <HStack>
            <Button
              border="1px solid"
              borderColor="#6A53FF"
              isLoading={isSendEmailLoading}
              disabled={isSendEmailLoading}
              onClick={sendMultipleMail}
            >
              Send Auth Email to Selected
            </Button>
            <Button
              border="1px solid"
              colorScheme={"red"}
              isLoading={isDeleteMultipleUsersLoading}
              disabled={isDeleteMultipleUsersLoading}
              onClick={handleDeleteMultipleUsers}
            >
              Delete Selected Users
            </Button>
          </HStack>
        )}
      </Flex>
      <Box>
        <Modal isOpen={editUserIsOpen} onClose={onCloseEditUser} size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {editUserIsOpen && (
                <EditUser
                  onModalClose={onCloseEditUser}
                  getUsers={props.getUsers}
                  userToUpdate={userToEdit}
                  resetUserToUpdate={() => setUserToEdit(null)}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
        <Table
          ref={tableRef}
          variant="simple"
          color="gray.500"
          mb="24px"
          mt="12px"
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      pe="10px"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
