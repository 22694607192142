// Chakra imports
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Icon,
  HStack,
  Spinner,
  SimpleGrid,
  Center,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { MdPieChartOutlined, MdRefresh } from "react-icons/md";
import { IQuestion } from "constants/types";
import { useEffect, useState } from "react";
import { getQuestionStatisticsById } from "services/QuestionService";
import ReactApexChart from "react-apexcharts";
import { useHistory } from "react-router-dom";
export default function Conversion(props: { question: IQuestion | null }) {
  const [questionStatistics, setQuestionStatistics] = useState<{
    answersDict: { [key: number]: string[] };
    answers: any[];
    pieChartData: number[];
  }>();
  const [isLoading, setIsLoading] = useState(true);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const history = useHistory();

  const pieChartOptions: any = {
    labels: props.question
      ? props.question.options.map((option) => option.title)
      : [],
    // 6 different colors
    colors: [
      "#4318FF",
      "#6AD2FF",
      "#EFF4FB",
      "#FF0000",
      "#ffff00",
      "#00ff00",
    ].slice(0, props.question ? props.question.options.length : 0),
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: [
        "#4318FF",
        "#6AD2FF",
        "#EFF4FB",
        "#FF0000",
        "#ffff00",
        "#00ff00",
      ].slice(0, props.question ? props.question.options.length : 0),
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  useEffect(() => {
    if (props.question != null) {
      getQuestionStatistics();
    }
  }, [props.question]);

  async function getQuestionStatistics() {
    if (!props.question) return;
    setIsLoading(true);
    const resp = await getQuestionStatisticsById(props.question.id);
    if (resp) {
      setQuestionStatistics(resp);
    }
    setIsLoading(false);
  }
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      {props.question == null ? (
        <Center height={"100%"}>
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            No Voting Result Available
          </Text>
        </Center>
      ) : isLoading ? (
        <Flex
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Spinner size="xl" color="brand.100" />
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Loading...
          </Text>
        </Flex>
      ) : (
        props.question &&
        questionStatistics && (
          <>
            <Flex
              px={{ base: "0px", "2xl": "10px" }}
              justifyContent="space-between"
              alignItems="center"
              w="100%"
              mb="8px"
            >
              <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
                Active Voting Results
              </Text>
              <HStack>
                {" "}
                <IconButton
                  aria-label="See Statistics"
                  icon={<Icon as={MdPieChartOutlined} />}
                  h="24px"
                  colorScheme="brandScheme"
                  onClick={() => {
                    history.push({
                      pathname: `/admin/question-stats/${props.question.id}`,
                      state: { questionData: props.question },
                    });
                  }}
                />
                <IconButton
                  aria-label="Refresh"
                  icon={<Icon as={MdRefresh} />}
                  h="24px"
                  isLoading={isLoading}
                  onClick={() => {
                    getQuestionStatistics();
                  }}
                />
              </HStack>
            </Flex>

            <ReactApexChart
              options={pieChartOptions}
              series={questionStatistics.pieChartData}
              type="pie"
              width="100%"
              height="55%"
            />
            <Card
              bg={cardColor}
              flexDirection="row"
              boxShadow={cardShadow}
              w="100%"
              p="15px"
              px="20px"
              mt="15px"
              mx="auto"
            >
              <SimpleGrid minChildWidth="120px" spacing="20px" w={"100%"}>
                {questionStatistics.pieChartData.length > 0 &&
                  questionStatistics.answers.length > 0 &&
                  props.question.options.map((option, index) => (
                    <>
                      <Flex
                        direction="column"
                        alignItems={"center"}
                        py="5px"
                        key={
                          // eslint-disable-next-line react/no-array-index-key
                          option.title
                        }
                      >
                        <Flex align="center">
                          <Box
                            h="8px"
                            w="8px"
                            bg={pieChartOptions.colors[index]}
                            borderRadius="50%"
                            me="4px"
                          />
                          <Text
                            fontSize="xs"
                            color="secondaryGray.600"
                            fontWeight="700"
                            mb="5px"
                          >
                            {option.title}
                          </Text>
                        </Flex>
                        <Text fontSize="lg" color={textColor} fontWeight="700">
                          {
                            // find the percentage of the option
                            "%" +
                              (
                                (questionStatistics.pieChartData[index] /
                                  questionStatistics.answers.length) *
                                100
                              ).toFixed(2) || 0
                          }
                        </Text>
                        {
                          // VOTE count
                          <Text
                            fontSize="xs"
                            color="secondaryGray.600"
                            mb="5px"
                          >
                            Number of vote casts :{" "}
                            {questionStatistics.pieChartData[index]}
                          </Text>
                        }
                      </Flex>

                      {/* {index !== props.question.options.length - 1 && (
                        <VSeparator
                          mx={{ base: "60px", xl: "60px", "2xl": "60px" }}
                        />
                      )} */}
                    </>
                  ))}
              </SimpleGrid>
              {/* <Flex direction="column" py="5px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="brand.500" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              Your files
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            63%
          </Text>
        </Flex>
        <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} />
        <Flex direction="column" py="5px" me="10px">
          <Flex align="center">
            <Box h="8px" w="8px" bg="#6AD2FF" borderRadius="50%" me="4px" />
            <Text
              fontSize="xs"
              color="secondaryGray.600"
              fontWeight="700"
              mb="5px"
            >
              System
            </Text>
          </Flex>
          <Text fontSize="lg" color={textColor} fontWeight="700">
            25%
          </Text>
        </Flex> */}
            </Card>
          </>
        )
      )}
    </Card>
  );
}
