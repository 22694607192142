// Chakra imports
import {
  Box,
  Flex,
  Text,
  Icon,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";

// Assets
import { MdPieChartOutlined } from "react-icons/md";
import { IQuestion } from "constants/types";
import { useHistory } from "react-router-dom";

export default function LastCreatedQuestions(props: {
  questions: IQuestion[];
}) {
  const history = useHistory();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      <Flex alignItems="center" w="100%" mb="30px">
        <Text color={textColor} fontSize="lg" fontWeight="700">
          Latest Questions
        </Text>
      </Flex>
      <Box px="11px" w="100%">
        {props.questions.map((question, index) => {
          return (
            <Flex w="100%" justify="space-between" mb="20px">
              <Text
                fontWeight="bold"
                color={textColor}
                fontSize="md"
                textAlign="start"
              >
                {question.questionText}
              </Text>
              <IconButton
                aria-label="See Statistics"
                icon={<Icon as={MdPieChartOutlined} />}
                h="24px"
                colorScheme="brandScheme"
                onClick={() =>
                  history.push({
                    pathname: `/admin/question-stats/${question.id}`,
                    state: { questionData: question },
                  })
                }
              />
            </Flex>
          );
        })}
      </Box>
    </Card>
  );
}
