import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import NotAuthorized from "components/notAuthorized/NotAuthorized";
import { ISmtpConfig } from "constants/types";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { storageService } from "services";
import { getMailSmtp, testMailSmtp } from "services/MailService";
import EditSmtp from "./components/EditSmtp";

function SmtpScreen() {
  const [smtpData, setSmtpData] = useState<ISmtpConfig>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const toast = useToast();
  const [testLoading, setTestLoading] = useState<boolean>(false);

  async function getSMTPCredentials() {
    setIsLoading(true);
    const smtpData = await getMailSmtp();
    setSmtpData(smtpData);
    setIsLoading(false);
  }

  useEffect(() => {
    getSMTPCredentials();
  }, []);

  async function testSmtpConnection() {
    setTestLoading(true);
    const res = await testMailSmtp();
    if (res) {
      toast({
        title: "Success",
        description: "SMTP Connection Successful",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "SMTP Connection Failed",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setTestLoading(false);
  }

  return storageService.getRole() !== "admin" ? (
    <NotAuthorized />
  ) : (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Container
        maxW="container.lg"
        p={"20px"}
        boxShadow={"0px 0px 10px 0px rgba(0, 0, 0, 0.1)"}
      >
        <Flex w={"100%"} flexDirection="column">
          {editMode ? (
            <EditSmtp
              smtpData={smtpData}
              closeEditMode={() => setEditMode(!editMode)}
            />
          ) : (
            <>
              <Heading as={"h3"} size="lg" textAlign={"center"}>
                SMTP Credentials
              </Heading>
              <HStack justifyContent={"flex-end"}>
                <IconButton
                  aria-label="Edit Credentials"
                  icon={<MdEdit />}
                  onClick={() => setEditMode(!editMode)}
                  colorScheme="blue"
                />
              </HStack>
              <HStack mt="10px">
                <Text fontSize="lg" fontWeight="600">
                  Host:
                </Text>
                <Text fontSize={"md"}>{smtpData?.host}</Text>
              </HStack>
              <HStack mt="10px">
                <Text fontSize="lg" fontWeight="600">
                  Port:
                </Text>
                <Text fontSize={"md"}>{smtpData?.port}</Text>
              </HStack>
              <HStack mt="10px">
                <Text fontSize="lg" fontWeight="600">
                  Username:
                </Text>
                <Text fontSize={"md"}>{smtpData?.username}</Text>
              </HStack>
              <HStack mt="10px">
                <Text fontSize="lg" fontWeight="600">
                  Password:
                </Text>
                <Text fontSize={"md"}>{smtpData?.password}</Text>
              </HStack>
              <HStack mt="10px">
                <Text fontSize="lg" fontWeight="600">
                  Sender:
                </Text>
                <Text fontSize={"md"}>{smtpData?.sender}</Text>
              </HStack>
              <Center>
                <Button
                  mt="20px"
                  colorScheme="blue"
                  onClick={testSmtpConnection}
                  isLoading={testLoading}
                >
                  Test SMTP Connection
                </Button>
              </Center>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  );
}

export default SmtpScreen;
