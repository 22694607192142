import axios from "axios";
import Routes from "constants/constants";
import { IReports } from "constants/types";

export async function getDashboardReports(): Promise<IReports | null> {
  try {
    const response = await axios.get(Routes.getReports);
    const responseJson = await response.data;
    return responseJson.success ? (responseJson.data as IReports) : null;
  } catch (error) {
    return null;
  }
}
