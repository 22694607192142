import axios from "axios";
import Routes from "constants/constants";
import { ILodgeUnits, Roles } from "constants/enums";
import { IAdvancedUser, IAdvancedUserWithId, IUser } from "constants/types";

export async function getAllUsers(): Promise<IUser[]> {
  try {
    const response = await axios.get(Routes.getAllUsers);
    const responseJson = await response.data;
    return responseJson.success ? (responseJson.users as IUser[]) : [];
  } catch (error) {
    return [];
  }
}

export async function createUser(
  email: string,
  name: string,
  surname: string,
  rightToVote: boolean,
  obedience: string,
  obedienceVoteWeight: number,
  gender: "male" | "female",
  city?: string,
  country?: string,
  phoneNumber?: string,
  mQuality?: string,
  attachedUnit?: ILodgeUnits,
  attendeeTitle?: string,
  bigLodgeName?: string,
  areaLodgeName?: string,
  regionLodgeName?: string,
  lodgeName?: string,
  voteWeight?: number,
  id?: string
): Promise<any> {
  try {
    const response = await axios.post(Routes.createUser, {
      email,
      name,
      surname,
      rightToVote,
      obedience,
      obedienceVoteWeight,
      bigLodgeName,
      areaLodgeName,
      regionLodgeName,
      lodgeName,
      gender,
      city,
      country,
      phoneNumber,
      mQuality,
      attachedUnit,
      attendeeTitle,
      voteWeight,
      id,
    });
    const responseJson = await response.data;
    return responseJson;
  } catch (error) {
    return [];
  }
}

export async function deleteUser(id: string): Promise<boolean> {
  try {
    const response = await axios.delete(`${Routes.deleteUser}/${id}`);
    const responseJson = await response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}

export async function getAdvancedUser(id: string): Promise<IAdvancedUser> {
  try {
    const response = await axios.get(`${Routes.getAdvancedUser}/${id}`);
    const responseJson = await response.data;
    return responseJson.success ? responseJson.user : null;
  } catch (error) {
    return null;
  }
}

export async function changeRoleOfUser(
  id: string,
  role: Roles
): Promise<{
  success: boolean;
  message: string;
}> {
  try {
    const response = await axios.post(`${Routes.changeUserRole}`, {
      id,
      role,
    });
    const responseJson = await response.data;
    return responseJson;
  } catch (error) {
    return null;
  }
}

export async function updateUser(
  id: string,
  email: string,
  name: string,
  surname: string,
  rightToVote: boolean,
  obedience: string,
  obedienceVoteWeight: number,
  gender: "male" | "female",
  city?: string,
  country?: string,
  phoneNumber?: string,
  mQuality?: string,
  attachedUnit?: ILodgeUnits,
  attendeeTitle?: string,
  bigLodgeName?: string,
  areaLodgeName?: string,
  regionLodgeName?: string,
  lodgeName?: string,
  voteWeight?: number
): Promise<any> {
  try {
    const response = await axios.post(`${Routes.updateUser}/${id}`, {
      email,
      name,
      surname,
      rightToVote,
      obedience,
      obedienceVoteWeight,
      bigLodgeName,
      areaLodgeName,
      regionLodgeName,
      lodgeName,
      gender,
      city,
      country,
      phoneNumber,
      mQuality,
      attachedUnit,
      attendeeTitle,
      voteWeight,
    });
    const responseJson = await response.data;
    return responseJson;
  } catch (error) {
    return [];
  }
}

export async function createMultipleUsers(
  users: IAdvancedUserWithId[]
): Promise<{
  success: boolean;
  message: string;
  data?: string[];
  type?: string;
}> {
  try {
    const body = {
      users,
    };
    const response = await axios.post(Routes.createMultipleUsers, body);
    const responseJson = await response.data;
    return responseJson;
  } catch (error: any) {
    return (
      error?.response?.data || {
        success: false,
        message: "Something went wrong",
        type: error?.response?.data?.type ?? "error",
        data: error.response.data,
      }
    );
  }
}
export async function deleteMultipleUsers(ids: string[]): Promise<boolean> {
  try {
    const response = await axios.post(`${Routes.deleteMultipleUsers}`, {
      ids,
    });
    const responseJson = response.data;
    return responseJson.success || false;
  } catch (error) {
    return false;
  }
}
