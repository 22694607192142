import { ILodgeUnits } from "constants/enums";
import * as yup from "yup";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Select,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { PhoneIcon } from "@chakra-ui/icons";
import { createUser } from "services/UserService";
import { IAdvancedUserWithId } from "constants/types";
import { useState } from "react";

export const AddUser = (addUserProps: {
  onModalClose: () => void;
  getUsers: () => Promise<void>;
  userDataToUpdate?: IAdvancedUserWithId;
}) => {
  const [useCustomId, setUseCustomId] = useState(false);
  const toast = useToast();
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    surname: yup.string().required("Surname is required"),
    rightToVote: yup.boolean().required("Right to vote is required"),
    obedience: yup.string().required("Obedience is required"),
    obedienceVoteWeight: yup
      .number()
      .required("Obedience vote weight is required"),
    bigLodgeName: yup.string().optional(),
    areaLodgeName: yup.string().optional(),
    regionLodgeName: yup.string().optional(),
    lodgeName: yup.string().optional(),
    // gender must be either male or female
    gender: yup
      .string()
      .oneOf(["male", "female"])
      .required("Gender is required"),
    city: yup.string().optional(),
    country: yup.string().optional(),
    phoneNumber: yup.string().optional(),
    mQuality: yup.string().optional(),
    voteWeight: yup.number().optional().default(1),
    attachedUnit: yup.string().oneOf(Object.values(ILodgeUnits)).optional(),
    attendeeTitle: yup.string().optional(),
    id: yup.string().optional(),
  });

  const userData = {
    name: addUserProps.userDataToUpdate?.name || "",
    email: addUserProps.userDataToUpdate?.email || "",
    surname: addUserProps.userDataToUpdate?.surname || "",
    rightToVote: addUserProps.userDataToUpdate?.rightToVote || false,
    obedience: addUserProps.userDataToUpdate?.obedience || "",
    obedienceVoteWeight:
      addUserProps.userDataToUpdate?.obedienceVoteWeight || 0,
    bigLodgeName: addUserProps.userDataToUpdate?.bigLodgeName,
    areaLodgeName: addUserProps.userDataToUpdate?.areaLodgeName,
    regionLodgeName: addUserProps.userDataToUpdate?.regionLodgeName,
    lodgeName: addUserProps.userDataToUpdate?.lodgeName,
    gender: addUserProps.userDataToUpdate?.gender || "",
    city: addUserProps.userDataToUpdate?.city,
    country: addUserProps.userDataToUpdate?.country,
    phoneNumber: addUserProps.userDataToUpdate?.phoneNumber,
    mQuality: addUserProps.userDataToUpdate?.mQuality,
    attachedUnit: addUserProps.userDataToUpdate?.attachedUnit,
    attendeeTitle: addUserProps.userDataToUpdate?.attendeeTitle,
    voteWeight: addUserProps.userDataToUpdate?.voteWeight || 1,
    id: addUserProps.userDataToUpdate?.id,
  };

  const initialValues = userData;

  const submitHandler = async (values: any, actions: any) => {
    try {
      let addUserResp = await createUser(
        values.email,
        values.name,
        values.surname,
        values.rightToVote,
        values.obedience,
        values.obedienceVoteWeight,
        values.gender,
        values.city,
        values.country,
        values.phoneNumber,
        values.mQuality,
        values.attachedUnit,
        values.attendeeTitle,
        values.bigLodgeName,
        values.areaLodgeName,
        values.regionLodgeName,
        values.lodgeName,
        values.voteWeight,
        values.id
      );
    } finally {
      actions.setSubmitting(false);
      toast({
        title: "Account created.",
        description: "We've created your account for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      addUserProps.getUsers();
      addUserProps.onModalClose();
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <Container
        maxW="3xl"
        mt={5}
        mb={5}
        borderWidth="1px"
        borderRadius="lg"
        p={6}
        boxShadow="xl"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Field name="questions">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="questions" fontSize="xl" mt={4}>
                      Create new user
                    </FormLabel>
                    <Box ml={4}>
                      <FieldArray {...field} name="questions" id="questions">
                        {(fieldArrayProps) => {
                          const { form } = fieldArrayProps;
                          const { values, errors, touched } = form;
                          const errorHandler = (name: any) => {
                            const error = getIn(errors, name);
                            const touch = getIn(touched, name);
                            return touch && error ? error : null;
                          };
                          return (
                            <div>
                              <Flex key={0} direction="column">
                                <FormControl isInvalid={errorHandler(`id`)}>
                                  <FormLabel htmlFor={`id`}>ID:</FormLabel>
                                  <Input
                                    name={`id`}
                                    as={Field}
                                    readOnly={!useCustomId}
                                    value={
                                      !useCustomId
                                        ? "Auto Generated"
                                        : values.id ?? ""
                                    }
                                    // mb={
                                    //     !errorHandler(
                                    //         `questions[${index}][title]`
                                    //     ) && 3
                                    // }
                                  />
                                  <Checkbox
                                    mt={2}
                                    onChange={() => {
                                      setUseCustomId(!useCustomId);
                                      form.setFieldValue(`id`, undefined);
                                    }}
                                  >
                                    Use custom ID
                                  </Checkbox>
                                  <FormErrorMessage>
                                    {errorHandler(`id`)}
                                  </FormErrorMessage>
                                </FormControl>
                                <HStack>
                                  <FormControl isInvalid={errorHandler(`name`)}>
                                    <FormLabel htmlFor={`name`}>
                                      Name:
                                    </FormLabel>
                                    <Input
                                      name={`name`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`name`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    mt={4}
                                    isInvalid={errorHandler(`surname`)}
                                  >
                                    <FormLabel htmlFor={`surname`}>
                                      Surname:
                                    </FormLabel>
                                    <Input
                                      name={`surname`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`surname`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </HStack>
                                <FormControl
                                  mt={4}
                                  isInvalid={errorHandler(`email`)}
                                >
                                  <FormLabel htmlFor={`email`}>
                                    E-Mail:
                                  </FormLabel>
                                  <Input
                                    name={`email`}
                                    as={Field}
                                    // mb={
                                    //     !errorHandler(
                                    //         `questions[${index}][title]`
                                    //     ) && 3
                                    // }
                                  />
                                  <FormErrorMessage>
                                    {errorHandler(`email`)}
                                  </FormErrorMessage>
                                </FormControl>
                                <HStack alignItems={"center"} mt={4}>
                                  <FormControl
                                    isInvalid={errorHandler(`gender`)}
                                  >
                                    <FormLabel htmlFor={`gender`}>
                                      Gender:
                                    </FormLabel>
                                    <Select
                                      placeholder="Select Gender"
                                      onChange={(e) => {
                                        form.setFieldValue(
                                          "gender",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                    </Select>
                                    <FormErrorMessage>
                                      {errorHandler(`gender`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={errorHandler(`rightToVote`)}
                                  >
                                    <FormLabel htmlFor={`rightToVote`}>
                                      Right To Vote:
                                    </FormLabel>
                                    <Select
                                      placeholder="Has Right to Vote"
                                      value={
                                        values.rightToVote ? "true" : "false"
                                      }
                                      onChange={(e) => {
                                        form.setFieldValue(
                                          "rightToVote",
                                          e.target.value === "true"
                                            ? true
                                            : false
                                        );
                                      }}
                                    >
                                      <option value={"true"}>Yes</option>
                                      <option value={"false"}>False</option>
                                    </Select>
                                    <FormErrorMessage>
                                      {errorHandler(`rightToVote`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </HStack>
                                <FormControl
                                  mt={4}
                                  isInvalid={errorHandler(`obedience`)}
                                >
                                  <FormLabel htmlFor={`obedience`}>
                                    Obedience:
                                  </FormLabel>
                                  <Input
                                    name={`obedience`}
                                    as={Field}
                                    // mb={
                                    //     !errorHandler(
                                    //         `questions[${index}][title]`
                                    //     ) && 3
                                    // }
                                  />
                                  <FormErrorMessage>
                                    {errorHandler(`obedience`)}
                                  </FormErrorMessage>
                                </FormControl>
                                {/* <FormControl
                                  mt={4}
                                  isInvalid={errorHandler(`attendeeTitle`)}
                                >
                                  <FormLabel htmlFor={`obedience`}>
                                    Attendee Title:
                                  </FormLabel>
                                  <Input
                                    name={`attendeeTitle`}
                                    as={Field}
                                    // mb={
                                    //     !errorHandler(
                                    //         `questions[${index}][title]`
                                    //     ) && 3
                                    // }
                                  />
                                  <FormErrorMessage>
                                    {errorHandler(`attendeeTitle`)}
                                  </FormErrorMessage>
                                </FormControl> */}
                                <HStack mt={4} alignItems="flex-end">
                                  <FormControl
                                    isInvalid={errorHandler(`voteWeight`)}
                                  >
                                    <FormLabel htmlFor={`voteWeight`}>
                                      <HStack alignItems={"center"}>
                                        <Text>
                                          In Total, on Behalf of How Many
                                          Obediences will the Vote be Cast
                                        </Text>
                                      </HStack>
                                    </FormLabel>

                                    <NumberInput
                                      defaultValue={values.voteWeight}
                                      min={0}
                                      max={100}
                                      value={values.voteWeight}
                                      onChange={(value) => {
                                        form.setFieldValue(
                                          "voteWeight",
                                          Number(value)
                                        );
                                      }}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                    <FormErrorMessage>
                                      {errorHandler(`voteWeight`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={errorHandler(
                                      `obedienceVoteWeight`
                                    )}
                                  >
                                    <FormLabel htmlFor={`obedienceVoteWeight`}>
                                      Obedience Vote Weight:
                                    </FormLabel>
                                    <NumberInput
                                      defaultValue={values.obedienceVoteWeight}
                                      min={0}
                                      max={100}
                                      value={values.obedienceVoteWeight}
                                      onChange={(value) => {
                                        form.setFieldValue(
                                          "obedienceVoteWeight",
                                          Number(value)
                                        );
                                      }}
                                    >
                                      <NumberInputField />
                                      <NumberInputStepper>
                                        <NumberIncrementStepper />
                                        <NumberDecrementStepper />
                                      </NumberInputStepper>
                                    </NumberInput>
                                    <FormErrorMessage>
                                      {errorHandler(`obedienceVoteWeight`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  {/* <FormControl
                                    isInvalid={errorHandler(`attachedUnit`)}
                                  >
                                    <FormLabel htmlFor={`attachedUnit`}>
                                      Attached Unit:
                                    </FormLabel>
                                    <Select
                                      placeholder="Attached Unit"
                                      onChange={(e) => {
                                        console.log("e", e.target.value);
                                        form.setFieldValue(
                                          "attachedUnit",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {Object.values(ILodgeUnits).map(
                                        (unit) => {
                                          return (
                                            <option value={unit}>{unit}</option>
                                          );
                                        }
                                      )}
                                    </Select>
                                    <FormErrorMessage>
                                      {errorHandler(`rightToVote`)}
                                    </FormErrorMessage>
                                  </FormControl> */}
                                </HStack>
                                {/* <HStack mt={4}>
                                  <FormControl
                                    isInvalid={errorHandler(`bigLodgeName`)}
                                  >
                                    <FormLabel htmlFor={`bigLodgeName`}>
                                      Big Lodge:
                                    </FormLabel>
                                    <Input
                                      name={`bigLodgeName`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`bigLodgeName`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={errorHandler(`areaLodgeName`)}
                                  >
                                    <FormLabel htmlFor={`areaLodgeName`}>
                                      Area Lodge:
                                    </FormLabel>
                                    <Input
                                      name={`areaLodgeName`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`areaLodgeName`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </HStack>
                                <HStack mt={4}>
                                  <FormControl
                                    isInvalid={errorHandler(`regionLodgeName`)}
                                  >
                                    <FormLabel htmlFor={`regionLodgeName`}>
                                      Region Lodge:
                                    </FormLabel>
                                    <Input
                                      name={`regionLodgeName`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`regionLodgeName`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    isInvalid={errorHandler(`lodgeName`)}
                                  >
                                    <FormLabel htmlFor={`lodgeName`}>
                                      Lodge:
                                    </FormLabel>
                                    <Input
                                      name={`lodgeName`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`lodgeName`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </HStack> */}
                                {/* <FormControl
                                  mt={4}
                                  isInvalid={errorHandler(`mQuality`)}
                                >
                                  <FormLabel htmlFor={`mQuality`}>
                                    M∴ Quality:
                                  </FormLabel>
                                  <Input
                                    name={`mQuality`}
                                    as={Field}
                                    // mb={
                                    //     !errorHandler(
                                    //         `questions[${index}][title]`
                                    //     ) && 3
                                    // }
                                  />
                                  <FormErrorMessage>
                                    {errorHandler(`mQuality`)}
                                  </FormErrorMessage>
                                </FormControl> */}
                                <HStack mt={4}>
                                  <FormControl
                                    isInvalid={errorHandler(`country`)}
                                  >
                                    <FormLabel htmlFor={`country`}>
                                      Country:
                                    </FormLabel>
                                    <Input
                                      name={`country`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`country`)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  {/* <FormControl isInvalid={errorHandler(`city`)}>
                                    <FormLabel htmlFor={`city`}>
                                      City:
                                    </FormLabel>
                                    <Input
                                      name={`city`}
                                      as={Field}
                                      // mb={
                                      //     !errorHandler(
                                      //         `questions[${index}][title]`
                                      //     ) && 3
                                      // }
                                    />
                                    <FormErrorMessage>
                                      {errorHandler(`city`)}
                                    </FormErrorMessage>
                                  </FormControl> */}
                                </HStack>
                                <FormControl
                                  isInvalid={errorHandler(`phoneNumber`)}
                                >
                                  <FormLabel htmlFor={`phoneNumber`}>
                                    Phone Number:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      children={<PhoneIcon color="gray.300" />}
                                    />
                                    <Input
                                      type="tel"
                                      name={`phoneNumber`}
                                      as={Field}
                                      placeholder="Phone number"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {errorHandler(`phoneNumber`)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>
                            </div>
                          );
                        }}
                      </FieldArray>
                    </Box>
                  </FormControl>
                )}
              </Field>
              <Center mt={4}>
                <Button
                  colorScheme="blue"
                  isLoading={props.isSubmitting}
                  onClick={addUserProps.onModalClose}
                >
                  Close
                </Button>
                <Button
                  ml={4}
                  colorScheme="green"
                  isLoading={props.isSubmitting}
                  type="submit"
                  disabled={!(props.isValid && props.dirty)}
                >
                  Create User
                </Button>
              </Center>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};
