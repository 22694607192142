import axios from "axios";
import Routes from "constants/constants";
import StorageKeys from "constants/storageKeys";
import { signInWithCustomToken } from "firebase/auth";
import { storageService } from "services";
import { auth } from "../firebase/firebase";

import { saveToken, setLocale } from "./StorageService";

export async function loginAsAdmin(email: string, password: string) {
  try {
    const response = await axios.post(Routes.adminLogin, { email, password });
    if (response.data.success) {
      setLocale(StorageKeys.user, response.data.user);
      setLocale(StorageKeys.role, response.data.role);
    }
    const respWithToken = await signInWithCustomToken(
      auth,
      response.data.token
    );
    if (respWithToken) {
      const userToken = await respWithToken.user.getIdToken(true);
      saveToken(userToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + userToken;
    }
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function signOutAsAdmin() {
  storageService.cleanLocaleStorage();
  await auth.signOut();
}
