import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  Switch,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
  Select,
  HStack,
} from "@chakra-ui/react";
import { CountdownUnits } from "constants/enums";
import { Field, FieldArray, Form, Formik, getIn } from "formik";
import { useState } from "react";
import { addNewQuestion } from "services/QuestionService";
import * as yup from "yup";
// import Navbar from '../../../common/Navbar';
// import { useAuth } from '../../../lib/auth';

const AddQuestion = (addQuestionProps: {
  onModalClose: any;
  getQuestions: () => Promise<void>;
}) => {
  const [optionCount, setOptionCount] = useState<number>(2);

  const questionsData: {
    title: string;
    options: { title: string }[];
    countdownEnabled: boolean;
    countdownTimeValue?: number;
    countdownTimeUnit?: CountdownUnits;
  } = {
    title: "",
    options: [{ title: "" }, { title: "" }],
    countdownEnabled: false,
    countdownTimeValue: 0,
    countdownTimeUnit: CountdownUnits.minutes,
  };

  const initialValues = questionsData;

  const validationSchema = yup.object().shape({
    title: yup.string().required("Required!"),
    // All option titles must be unique
    options: yup.array().of(
      yup
        .object()
        .shape({
          title: yup.string().required("Required!"),
        })
        .test(
          "unique",
          "All option titles must be unique",
          function (value, { parent, createError }) {
            if (!value) return true;
            const titles = parent.map((option: any) => option.title);
            const returnValue =
              titles.filter((t: any) => t === value.title).length === 1;
            if (!returnValue) {
              return createError({
                message: "All option titles must be unique",
                path: `[${this.path}].title`,
              });
            }
            return returnValue;
          }
        )
    ),
    countdownEnabled: yup.boolean().required("Required!"),
    countdownTimeValue: yup.number().when("countdownEnabled", {
      is: true,
      then(schema) {
        return schema
          .required("Required!")
          .min(1, "Must be greater than 0")
          .max(60, "Must be less than 60");
      },
      otherwise(schema) {
        return schema.notRequired();
      },
    }),
    countdownTimeUnit: yup.number().when("countdownEnabled", {
      is: true,
      then(schema) {
        return schema
          .required("Required!")
          .min(1, "Must be valid unit")
          .max(3, "Must be valid unit");
      },
      otherwise(schema) {
        return schema.notRequired();
      },
    }),
  });

  const submitHandler = async (values: any, actions: any) => {
    try {
      let addQuestionResp = await addNewQuestion(
        values.title,
        values.options,
        values.countdownEnabled ? values.countdownTimeValue : undefined,
        values.countdownEnabled ? values.countdownTimeUnit : undefined
      );
    } finally {
      actions.setSubmitting(false);
      addQuestionProps.getQuestions();
      addQuestionProps.onModalClose();
    }
  };

  return (
    <>
      {/* <Navbar /> */}
      <Container
        maxW="3xl"
        mt={5}
        mb={5}
        borderWidth="1px"
        borderRadius="lg"
        p={6}
        boxShadow="xl"
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Field name="questions">
                {({ field }: any) => (
                  <FormControl>
                    <FormLabel htmlFor="questions" fontSize="xl" mt={4}>
                      Enter your question data:
                    </FormLabel>
                    <Box ml={4}>
                      <FieldArray {...field} name="questions" id="questions">
                        {(fieldArrayProps) => {
                          const { form } = fieldArrayProps;
                          const { values, errors, touched } = form;
                          const errorHandler = (name: any) => {
                            const error = getIn(errors, name);
                            const touch = getIn(touched, name);
                            return touch && error ? error : null;
                          };
                          return (
                            <div>
                              <Flex key={0} direction="column">
                                <FormControl isInvalid={errorHandler(`title`)}>
                                  <FormLabel htmlFor={`title`}>
                                    Question Title:
                                  </FormLabel>
                                  <Input name={`title`} as={Field} />
                                  <FormErrorMessage>
                                    {errorHandler(`title`)}
                                  </FormErrorMessage>
                                </FormControl>
                                <FormControl
                                  isInvalid={errorHandler(`options`)}
                                  id="options"
                                >
                                  <Text mt="8px">Number of Options:</Text>
                                  <NumberInput
                                    defaultValue={optionCount}
                                    min={2}
                                    max={6}
                                    value={optionCount}
                                    onChange={(value) => {
                                      setOptionCount(Number(value));
                                      if (
                                        Number(value) <
                                        form.values.options.length
                                      ) {
                                        questionsData.options =
                                          form.values.options.slice(
                                            0,
                                            Number(value)
                                          );
                                        form.setFieldValue(
                                          "options",
                                          questionsData.options
                                        );
                                      } else {
                                        questionsData.options = Array(
                                          Number(value)
                                        )
                                          .fill(0)
                                          .map((option, subIndex) => {
                                            return {
                                              title: form.values.options[
                                                subIndex
                                              ]
                                                ? form.values.options[subIndex]
                                                    .title
                                                : "",
                                            };
                                          });
                                        form.setFieldValue(
                                          "options",
                                          questionsData.options
                                        );
                                      }
                                    }}
                                  >
                                    <NumberInputField />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                  <SimpleGrid
                                    minChildWidth="300px"
                                    spacing="10px"
                                    mt={4}
                                  >
                                    {Array(optionCount)
                                      .fill(0)
                                      .map((option, subIndex) => (
                                        <FormControl
                                          mb={2}
                                          key={subIndex}
                                          isInvalid={errorHandler(
                                            `options[${subIndex}].title`
                                          )}
                                        >
                                          <FormLabel
                                            htmlFor={`options[${subIndex}].title`}
                                          >
                                            {"Option " + (subIndex + 1) + ":"}
                                          </FormLabel>
                                          <Input
                                            name={`options[${subIndex}].title`}
                                            as={Field}
                                          />
                                          <FormErrorMessage>
                                            {errorHandler(
                                              `options[${subIndex}].title`
                                            )}
                                          </FormErrorMessage>
                                        </FormControl>
                                      ))}
                                  </SimpleGrid>
                                  <FormErrorMessage>
                                    {errorHandler(`options`)}
                                  </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                  mt={4}
                                  display="flex"
                                  flexDirection={"column"}
                                  alignItems={"flex-start"}
                                  isInvalid={errorHandler(`title`)}
                                  mb={{ base: 4 }}
                                >
                                  <HStack>
                                    <FormLabel htmlFor="countdown" mb="0">
                                      Enable Countdown ?
                                    </FormLabel>
                                    <Switch
                                      id="countdown"
                                      onChange={(e) => {
                                        form.setFieldValue(
                                          "countdownEnabled",
                                          e.target.checked
                                        );
                                        if (!e.target.checked) {
                                          form.setFieldValue(
                                            "countdownTimeValue",
                                            undefined
                                          );
                                          form.setFieldValue(
                                            "countdownTimeUnit",
                                            undefined
                                          );
                                        } else {
                                          form.setFieldValue(
                                            "countdownTimeValue",
                                            1
                                          );
                                          form.setFieldValue(
                                            "countdownTimeUnit",
                                            1
                                          );
                                        }
                                      }}
                                    />
                                  </HStack>
                                  {form.values.countdownEnabled && (
                                    <Flex direction="row" mt={4} w={"100%"}>
                                      <FormControl
                                        isInvalid={errorHandler(
                                          `countdownTimeValue`
                                        )}
                                        id="countdownTimeValue"
                                      >
                                        <FormLabel
                                          htmlFor={`countdownTimeValue`}
                                        >
                                          Countdown Time Value:
                                        </FormLabel>
                                        <NumberInput
                                          defaultValue={
                                            values.countdownTimeValue
                                          }
                                          min={1}
                                          max={60}
                                          value={values.countdownTimeValue || 1}
                                          onChange={(value) => {
                                            form.setFieldValue(
                                              "countdownTimeValue",
                                              Number(value)
                                            );
                                          }}
                                        >
                                          <NumberInputField />
                                          <NumberInputStepper>
                                            <NumberIncrementStepper />
                                            <NumberDecrementStepper />
                                          </NumberInputStepper>
                                        </NumberInput>
                                        <FormErrorMessage>
                                          {errorHandler(`countdownTimeValue`)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <FormControl
                                        isInvalid={errorHandler(
                                          `countdownTimeUnit`
                                        )}
                                        id="countdownTimeUnit"
                                        ml={4}
                                      >
                                        <FormLabel
                                          htmlFor={`countdownTimeUnit`}
                                        >
                                          Countdown Time Unit:
                                        </FormLabel>
                                        <Select
                                          placeholder="Select Time Unit"
                                          value={values.countdownTimeUnit}
                                          onChange={(e) => {
                                            form.setFieldValue(
                                              "countdownTimeUnit",
                                              Number(e.target.value)
                                            );
                                          }}
                                        >
                                          {Object.values(CountdownUnits)
                                            .filter((v) => isNaN(Number(v)))
                                            .map((unit, index) => {
                                              return (
                                                <option
                                                  id={unit.toString()}
                                                  value={
                                                    // numberic value of the enum
                                                    index + 1
                                                  }
                                                >
                                                  {unit}
                                                </option>
                                              );
                                            })}
                                        </Select>
                                        <FormErrorMessage>
                                          {errorHandler(`countdownTimeUnit`)}
                                        </FormErrorMessage>
                                      </FormControl>
                                    </Flex>
                                  )}
                                  <FormErrorMessage>
                                    {errorHandler(`countdown`)}
                                  </FormErrorMessage>
                                </FormControl>
                              </Flex>
                            </div>
                          );
                        }}
                      </FieldArray>
                    </Box>
                  </FormControl>
                )}
              </Field>
              <Center>
                <Button
                  colorScheme="blue"
                  isLoading={props.isSubmitting}
                  onClick={addQuestionProps.onModalClose}
                >
                  Close
                </Button>
                <Button
                  ml={4}
                  colorScheme="green"
                  isLoading={props.isSubmitting}
                  type="submit"
                  disabled={!(props.isValid && props.dirty)}
                >
                  Submit Quiz
                </Button>
              </Center>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default AddQuestion;
