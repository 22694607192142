import { Box, Text } from "@chakra-ui/react";

function NotAuthorized() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      h="100vh"
    >
      <Text fontSize="2xl" fontWeight="bold">
        You are not authorized to view this page
      </Text>
    </Box>
  );
}

export default NotAuthorized;
