// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { read, utils } from "xlsx";
import * as yup from "yup";
// Assets
import { MdUpload } from "react-icons/md";
import ExcelDropzone from "./ExcelDropzone";
import { useEffect, useState } from "react";
import { IAdvancedUserWithId } from "constants/types";
import {
  Field,
  FieldArray,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikTouched,
  getIn,
} from "formik";
import React from "react";
import { createMultipleUsers } from "services/UserService";
import { ILodgeUnits } from "constants/enums";
import DeleteButtonWithDialog from "components/deleteButtonWithDialog/DeleteButtonWithDialog";
import { RiErrorWarningLine } from "react-icons/ri";
import { PhoneIcon } from "@chakra-ui/icons";

interface IExcelUsers {
  users: IAdvancedUserWithId[];
}

export default function UploadExcel(props: {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) {
  const { isOpen, onClose } = props;
  const [isImporting, setIsImporting] = useState(false);
  const [csvRows, setCsvRows] = useState<any[]>([]);
  const [formValues, setFormValues] = useState<IExcelUsers>({
    users: [],
  });
  const toast = useToast();

  useEffect(() => {
    setCsvRows([]);
  }, [isOpen]);

  useEffect(() => {
    if (csvRows.length === 0) {
    }
  }, [csvRows]);

  const validationSchema = yup.object().shape({
    users: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup
          .string()
          .email("Email is invalid")
          .required("Email is required"),
        surname: yup.string().required("Surname is required"),
        rightToVote: yup.boolean().required("Right to vote is required"),
        obedience: yup.string().required("Obedience is required"),
        obedienceVoteWeight: yup
          .number()
          .required("Obedience vote weight is required"),
        bigLodgeName: yup.string().optional(),
        areaLodgeName: yup.string().optional(),
        regionLodgeName: yup.string().optional(),
        lodgeName: yup.string().optional(),
        gender: yup
          .string()
          .oneOf(["male", "female"])
          .required("Gender is required"),
        city: yup.string().optional(),
        country: yup.string().optional(),
        phoneNumber: yup.string().optional(),
        mQuality: yup.string().optional(),
        attachedUnit: yup.string().oneOf(Object.values(ILodgeUnits)).optional(),
        attendeeTitle: yup.string().optional(),
        id: yup.string().optional(),
      })
    ),
  });

  const handleImportExcel = (files: File[], event: any) => {
    setIsImporting(true);
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          setCsvRows(
            // ignore first row
            rows
            //   rows.slice(1, rows.length)
          );

          setFormValues({
            users: rows.map((row) => {
              const userDataToCreate: IAdvancedUserWithId = {
                obedience: (Object.values(row)[0] as string | undefined) || "",
                rightToVote:
                  (Object.values(row)[1] as string | undefined) === "Yes"
                    ? true
                    : false,
                obedienceVoteWeight:
                  (Object.values(row)[2] as number | undefined) || 1,
                name: Object.values(row)[3] as string | undefined,
                surname: Object.values(row)[4] as string | undefined,
                email: Object.values(row)[5] as string | undefined,
                gender: (Object.values(row)[6] as "male" | "female") || "male",
                city: Object.values(row)[7] as string | undefined,
                country: Object.values(row)[8] as string | undefined,
                phoneNumber:
                  (Object.values(row)[9] ?? "").toString() || undefined,
                id: (Object.values(row)[10] as string | undefined) || undefined,
              };
              return userDataToCreate;
            }),
          });
        }
      };
      reader.readAsArrayBuffer(file);
      setIsImporting(false);
    }
  };

  function removeSelectedIndex(index: number) {
    const newCsvRows = csvRows.filter((row, i) => i !== index);
    setCsvRows(newCsvRows);
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Import Users via CSV</ModalHeader>
        <HStack justifyContent={"flex-end"}>
          <a
            href={require("../../../../assets/csv/sample-users.xlsx")}
            download="sample-users"
            target="_blank"
          >
            <Button
              colorScheme="green"
              width={"fit-content"}
              mr={"24px"}
              onClick={() => {}}
            >
              Download Sample CSV
            </Button>
          </a>
        </HStack>
        <ModalCloseButton />
        <ModalBody>
          {isImporting ? (
            <Spinner />
          ) : formValues.users.length > 0 ? (
            <Card mb="20px" alignItems="center" p="20px" w={"100%"}>
              <Accordion allowToggle width={{ base: "100%", "2xl": "100%" }}>
                <Formik
                  initialValues={formValues}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  validateOnMount={true}
                  validateOnBlur={true}
                  onSubmit={async (values, actions) => {
                    // Validate form
                    const isValid = await validationSchema.isValid(values);
                    if (isValid) {
                      const resp = await createMultipleUsers(values.users);
                      const success = resp?.success ?? false;
                      if (success) {
                        toast({
                          title: "Users created successfully",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                          position: "top",
                        });
                        onClose();
                      } else {
                        if (resp.data) {
                          if (resp.type === "id") {
                            const errorUserIds = resp.data as string[];
                            const errorUsers = values.users.filter((user) =>
                              errorUserIds.includes(user.id)
                            );
                            // set the errors on id field for the users that failed
                            // first find the index of the user in the form values
                            const errorUserIndexes = errorUsers.map(
                              (errorUser) =>
                                values.users.findIndex(
                                  (user) => user.id === errorUser.id
                                )
                            );
                            // then set the error on the id field
                            errorUserIndexes.forEach((index) => {
                              actions.setFieldError(
                                `users.${index}.id`,
                                "User with this id already exists"
                              );
                            });
                          }
                          if (resp.type === "email") {
                            const errorUserEmails = resp.data as string[];
                            const errorUsers = values.users.filter((user) =>
                              errorUserEmails.includes(user.email)
                            );
                            // set the errors on id field for the users that failed
                            // first find the index of the user in the form values
                            const errorUserIndexes = errorUsers.map(
                              (errorUser) =>
                                values.users.findIndex(
                                  (user) => user.email === errorUser.email
                                )
                            );
                            // then set the error on the id field
                            errorUserIndexes.forEach((index) => {
                              actions.setFieldError(
                                `users.${index}.email`,
                                "User with this email already exists"
                              );
                            });
                          }
                          toast({
                            title: "Error creating users",
                            description: resp?.message ?? "",
                            status: "error",
                            duration: 3000,
                            isClosable: true,
                            position: "top",
                          });
                        } else {
                          const validErrResp = resp as any;
                          if (validErrResp?.name && validErrResp?.details) {
                            toast({
                              title: "Error creating users",
                              description:
                                validErrResp?.details?.body[0]?.message ?? "",
                              status: "error",
                              duration: 20000,
                              isClosable: true,
                              position: "top",
                            });
                          }
                        }
                      }
                    } else {
                      toast({
                        title: "Please fill all fields",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                        position: "top",
                      });
                    }
                  }}
                >
                  {(props: FormikProps<IExcelUsers>) => (
                    <Stack>
                      <HStack>
                        <Text
                          fontSize="xl"
                          fontWeight="bold"
                          color={brandColor}
                        >
                          Errors:{" "}
                        </Text>
                        <Text fontSize="xl" fontWeight="bold" color="red">
                          {props.errors.users
                            ? Object.values(props.errors.users).filter(
                                (error) => error != null
                              ).length
                            : 0}
                        </Text>
                      </HStack>
                      <Form>
                        <FieldArray name="users">
                          {(fieldArrayProps) => {
                            const { form } = fieldArrayProps;
                            const {
                              values,
                              errors,
                              touched,
                              isValid,
                            }: {
                              values: IExcelUsers;
                              errors: FormikErrors<IExcelUsers>;
                              touched: FormikTouched<IExcelUsers>;
                              isValid: boolean;
                            } = form;
                            form.enableReinitialize = true;
                            const errorHandler = (name: any, index: any) => {
                              const error = getIn(
                                errors.users ? errors.users[index] : errors,
                                name
                              );
                              return error ? error : null;
                            };
                            return (
                              <Accordion allowMultiple>
                                {csvRows.map((row: any, index: number) => {
                                  return (
                                    <AccordionItem key={index} w={"100%"}>
                                      <AccordionButton borderColor={"red"}>
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="left"
                                        >
                                          <HStack>
                                            <Text>
                                              {values.users[index].name
                                                ? values.users[index].name
                                                : "Create User"}
                                            </Text>
                                            {
                                              // Error icon if form is not valid
                                              errors.users &&
                                                errors.users[index] != null && (
                                                  <Tooltip label="Please fill the invalid user fields">
                                                    <Icon
                                                      // height
                                                      ml={2}
                                                      as={RiErrorWarningLine}
                                                      color="red.500"
                                                    />
                                                  </Tooltip>
                                                )
                                            }
                                          </HStack>
                                        </Box>

                                        <DeleteButtonWithDialog
                                          title="Delete User"
                                          description="Are you sure you want to delete this user from excel?"
                                          onConfirm={async () => {
                                            form.values.users.splice(index, 1);
                                            form.setFieldValue(
                                              `users`,
                                              form.values.users
                                            );
                                            // Remove selected index from users[index]
                                            removeSelectedIndex(index);
                                          }}
                                        />

                                        <AccordionIcon ml={4} />
                                      </AccordionButton>
                                      <AccordionPanel pb={4}>
                                        <Stack spacing={4}>
                                          <FormControl
                                            isInvalid={errorHandler(
                                              `id`,
                                              index
                                            )}
                                          >
                                            <FormLabel htmlFor={`id`}>
                                              ID:
                                            </FormLabel>
                                            <Input
                                              name={`id`}
                                              as={Field}
                                              readOnly={
                                                values.users[index].id == null
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  `users[${index}].id`,
                                                  e.target.value
                                                );
                                              }}
                                              value={
                                                values.users[index].id
                                                  ? values.users[index].id
                                                  : "Auto Generated"
                                              }
                                              // mb={
                                              //     !errorHandler(
                                              //         `questions[${index}][title]`
                                              //     ) && 3
                                              // }
                                            />
                                            <FormErrorMessage>
                                              {errorHandler(`id`, index)}
                                            </FormErrorMessage>
                                          </FormControl>
                                          <HStack>
                                            <FormControl
                                              isInvalid={errorHandler(
                                                `name`,
                                                index
                                              )}
                                            >
                                              <FormLabel htmlFor={`name`}>
                                                Name:
                                              </FormLabel>
                                              <Input
                                                name={`name`}
                                                as={Field}
                                                onChange={(e: any) => {
                                                  form.setFieldValue(
                                                    `users[${index}].name`,
                                                    e.target.value
                                                  );
                                                }}
                                                value={values.users[index].name}
                                                // mb={
                                                //     !errorHandler(
                                                //         `questions[${index}][title]`
                                                //     ) && 3
                                                // }
                                              />
                                              <FormErrorMessage>
                                                {errorHandler(`name`, index)}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl
                                              mt={4}
                                              isInvalid={errorHandler(
                                                `surname`,
                                                index
                                              )}
                                            >
                                              <FormLabel htmlFor={`surname`}>
                                                Surname:
                                              </FormLabel>
                                              <Input
                                                name={`surname`}
                                                as={Field}
                                                value={
                                                  values.users[index].surname
                                                }
                                                onChange={(e: any) => {
                                                  form.setFieldValue(
                                                    `users[${index}].surname`,
                                                    e.target.value
                                                  );
                                                }}
                                                // mb={
                                                //     !errorHandler(
                                                //         `questions[${index}][title]`
                                                //     ) && 3
                                                // }
                                              />
                                              <FormErrorMessage>
                                                {errorHandler(`surname`, index)}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </HStack>
                                          <FormControl
                                            mt={4}
                                            isInvalid={errorHandler(
                                              `email`,
                                              index
                                            )}
                                          >
                                            <FormLabel htmlFor={`email`}>
                                              E-Mail:
                                            </FormLabel>
                                            <Input
                                              name={`email`}
                                              as={Field}
                                              value={values.users[index].email}
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  `users[${index}].email`,
                                                  e.target.value
                                                );
                                              }}
                                              // mb={
                                              //     !errorHandler(
                                              //         `questions[${index}][title]`
                                              //     ) && 3
                                              // }
                                            />
                                            <FormErrorMessage>
                                              {errorHandler(`email`, index)}
                                            </FormErrorMessage>
                                          </FormControl>
                                          <HStack alignItems={"center"} mt={4}>
                                            <FormControl
                                              isInvalid={errorHandler(
                                                `gender`,
                                                index
                                              )}
                                            >
                                              <FormLabel htmlFor={`gender`}>
                                                Gender:
                                              </FormLabel>
                                              <Select
                                                placeholder="Select Gender"
                                                value={
                                                  values.users[index].gender
                                                }
                                                onChange={(e) => {
                                                  form.setFieldValue(
                                                    `users[${index}].gender`,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                <option value="male">
                                                  Male
                                                </option>
                                                <option value="female">
                                                  Female
                                                </option>
                                              </Select>
                                              <FormErrorMessage>
                                                {errorHandler(`gender`, index)}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl
                                              isInvalid={errorHandler(
                                                `rightToVote`,
                                                index
                                              )}
                                            >
                                              <FormLabel
                                                htmlFor={`rightToVote`}
                                              >
                                                Right To Vote:
                                              </FormLabel>
                                              <Select
                                                placeholder="Has Right to Vote"
                                                value={
                                                  values.users[index]
                                                    .rightToVote
                                                    ? "true"
                                                    : "false"
                                                }
                                                onChange={(e) => {
                                                  form.setFieldValue(
                                                    `users[${index}].rightToVote`,
                                                    e.target.value === "true"
                                                      ? true
                                                      : false
                                                  );
                                                }}
                                              >
                                                <option value={"true"}>
                                                  Yes
                                                </option>
                                                <option value={"false"}>
                                                  False
                                                </option>
                                              </Select>
                                              <FormErrorMessage>
                                                {errorHandler(
                                                  `rightToVote`,
                                                  index
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </HStack>
                                          <FormControl
                                            mt={4}
                                            isInvalid={errorHandler(
                                              `obedience`,
                                              index
                                            )}
                                          >
                                            <FormLabel htmlFor={`obedience`}>
                                              Obedience:
                                            </FormLabel>
                                            <Input
                                              name={`obedience`}
                                              as={Field}
                                              value={
                                                values.users[index].obedience
                                              }
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  `users[${index}].obedience`,
                                                  e.target.value
                                                );
                                              }}
                                              // mb={
                                              //     !errorHandler(
                                              //         `questions[${index}][title]`
                                              //     ) && 3
                                              // }
                                            />
                                            <FormErrorMessage>
                                              {errorHandler(`obedience`, index)}
                                            </FormErrorMessage>
                                          </FormControl>
                                          <HStack mt={4}>
                                            <FormControl
                                              isInvalid={errorHandler(
                                                `obedienceVoteWeight`,
                                                index
                                              )}
                                            >
                                              <FormLabel
                                                htmlFor={`obedienceVoteWeight`}
                                              >
                                                Obedience Vote Weight:
                                              </FormLabel>
                                              <NumberInput
                                                defaultValue={
                                                  values.users[index]
                                                    .obedienceVoteWeight
                                                }
                                                min={0}
                                                max={100}
                                                value={
                                                  values.users[index]
                                                    .obedienceVoteWeight
                                                }
                                                onChange={(value) => {
                                                  form.setFieldValue(
                                                    `users[${index}].obedienceVoteWeight`,
                                                    value
                                                  );
                                                }}
                                              >
                                                <NumberInputField />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                              <FormErrorMessage>
                                                {errorHandler(
                                                  `obedienceVoteWeight`,
                                                  index
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </HStack>
                                          <HStack mt={4}>
                                            <FormControl
                                              isInvalid={errorHandler(
                                                `country`,
                                                index
                                              )}
                                            >
                                              <FormLabel htmlFor={`country`}>
                                                Country:
                                              </FormLabel>
                                              <Input
                                                name={`country`}
                                                as={Field}
                                                value={
                                                  values.users[index].country
                                                }
                                                onChange={(e: any) => {
                                                  form.setFieldValue(
                                                    `users[${index}].country`,
                                                    e.target.value
                                                  );
                                                }}
                                                // mb={
                                                //     !errorHandler(
                                                //         `questions[${index}][title]`
                                                //     ) && 3
                                                // }
                                              />
                                              <FormErrorMessage>
                                                {errorHandler(`country`, index)}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </HStack>
                                          <FormControl
                                            isInvalid={errorHandler(
                                              `phoneNumber`,
                                              index
                                            )}
                                          >
                                            <FormLabel htmlFor={`phoneNumber`}>
                                              Phone Number:
                                            </FormLabel>
                                            <InputGroup>
                                              <InputLeftElement
                                                pointerEvents="none"
                                                children={
                                                  <PhoneIcon color="gray.300" />
                                                }
                                              />
                                              <Input
                                                type="tel"
                                                name={`phoneNumber`}
                                                as={Field}
                                                value={
                                                  values.users[index]
                                                    .phoneNumber
                                                }
                                                placeholder="Phone number"
                                                onChange={(e: any) => {
                                                  form.setFieldValue(
                                                    `users[${index}].phoneNumber`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </InputGroup>
                                            <FormErrorMessage>
                                              {errorHandler(
                                                `phoneNumber`,
                                                index
                                              )}
                                            </FormErrorMessage>
                                          </FormControl>
                                        </Stack>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  );
                                })}
                              </Accordion>
                            );
                          }}
                        </FieldArray>
                        <Center mt={4}>
                          {csvRows.length > 0 && (
                            <ModalFooter>
                              <Button
                                colorScheme="blue"
                                mr={3}
                                onClick={onClose}
                              >
                                Close
                              </Button>
                              <Button
                                colorScheme="green"
                                isLoading={props.isSubmitting}
                                type="submit"
                                disabled={!props.isValid}
                              >
                                Create{" "}
                                {csvRows.length +
                                  " " +
                                  (csvRows.length > 1 ? "Users" : "User")}
                              </Button>
                            </ModalFooter>
                          )}
                        </Center>
                      </Form>
                    </Stack>
                  )}
                </Formik>
                {/* <Formik
                  initialValues={{
                    users: {
                      obedience: "",
                    },
                  }}
                  validateOnChange={true}
                  validateOnMount={true}
                  validateOnBlur={true}
                  validationSchema={validationSchema}
                  onSubmit={formik.handleSubmit}
                >
                  {(formikProps) => <Form>
                    formValues.map((row: any, index: number) => {
                      console.log("formValues props", formikProps);
                      return (

                        <AddUserAccordion
                          key={index}
                          userDataToUpdate={formValues[index]}
                          removeAccordion={() => {
                            removeSelectedIndex(index);
                          }}
                          formikProps={formikProps}
                          setIsValid={(isValid: boolean) => {
                            const newFormValidations = [...formValidations];
                            newFormValidations[index] = isValid;
                            setFormValidations(newFormValidations);
                          }}
                          setUserDataToUpdate={(
                            userData: Omit<IAdvancedUser, "id">
                          ) => {
                            const newFormValues = [...formValues];
                            newFormValues[index] = userData;
                            setFormValues(newFormValues);
                          }}
                        />
                      );
                    })</Form>}
                </Formik> */}
              </Accordion>
            </Card>
          ) : (
            <Card height={"300px"} mb="20px" alignItems="center" p="20px">
              <Flex h="100%" direction={{ base: "column", "2xl": "row" }}>
                <ExcelDropzone
                  handleImport={handleImportExcel}
                  w={{ base: "100%", "2xl": "268px" }}
                  me="36px"
                  maxH={"100%"}
                  minH={"100%"}
                  content={
                    isImporting ? (
                      <Spinner size={"xl"} />
                    ) : (
                      <Box>
                        <Icon
                          as={MdUpload}
                          w="80px"
                          h="80px"
                          color={brandColor}
                        />
                        <Flex justify="center" mx="auto" mb="12px">
                          <Text
                            fontSize="xl"
                            fontWeight="700"
                            color={brandColor}
                          >
                            Upload Files
                          </Text>
                        </Flex>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          CSV files are allowed
                        </Text>
                      </Box>
                    )
                  }
                />
              </Flex>
            </Card>
          )}
        </ModalBody>
        {/* {csvRows.length > 0 && (
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="green" onClick={handleCreate}>
              Create{" "}
              {csvRows.length + " " + (csvRows.length > 1 ? "Users" : "User")}
            </Button>
          </ModalFooter>
        )} */}
      </ModalContent>
    </Modal>
  );
}
