import { Box, Center, Spinner } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { IUser } from "constants/types";
import UsersTable from "./components/UsersTable";
import { getAllUsers } from "services/UserService";

export default function Users() {
  const [userTableData, setUserTableData] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  async function getUsers() {
    const allUsers = await getAllUsers();
    setUserTableData(allUsers);
    setIsLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <UsersTable tableData={userTableData} getUsers={getUsers} />
      )}
    </Box>
  );
}
