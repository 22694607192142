import { Roles } from "constants/enums";
import StorageKeys from "constants/storageKeys";

export const saveToken = (token: string) => {
  localStorage.setItem(StorageKeys.token, token);
};

export const getUserJson = () =>
  JSON.parse(localStorage.getItem(StorageKeys.user) || "{}");

export const getToken = () => localStorage.getItem(StorageKeys.token);

export const getRole = () =>
  JSON.parse(localStorage.getItem(StorageKeys.role)) as Roles;

export const cleanLocaleStorage = () => {
  localStorage.clear();
};

export const setLocale = (key: any, el: any) => {
  localStorage.setItem(key, JSON.stringify(el));
};
